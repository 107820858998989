import { MakeOfferAction_artist$key } from "__generated__/MakeOfferAction_artist.graphql";
import { ReactElement } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { ResponsiveValue } from "styled-system";

import Ic24ArrowRight from "../../../../../../../imgs/icons/ic24-arrow-right.svg";
import { IButtonIconProps } from "../../../../../../components/01_Core/Buttons/Button";
import LoadingButton from "../../../../../../components/01_Core/Buttons/LoadingButton";
import theme from "../../../../../../theme/theme";
import {
  rvMap,
  useActiveResponsiveValue
} from "../../../../../../utils/responsiveUtils";
import { useMessageActor } from "../../../../hooks/useMessage";

type MakeOfferActionSize = "small" | "large";
interface IMakeOfferActionProps {
  size: ResponsiveValue<MakeOfferActionSize>;
  artist: MakeOfferAction_artist$key;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=226%3A1676
 */
function MakeOfferAction(props: IMakeOfferActionProps): ReactElement {
  const artist = useFragment(
    graphql`
      fragment MakeOfferAction_artist on ArtistNode {
        ...useMessage_actor
        displayName
      }
    `,
    props.artist
  );

  const activeSize = useActiveResponsiveValue(props.size);
  const [messageArtist, isInFlight] = useMessageActor(artist, {
    isMakingOffer: true
  });

  const children =
    activeSize === "small"
      ? "Make offer"
      : `Make ${artist.displayName} an offer`;
  const iconProps: IButtonIconProps =
    activeSize === "small"
      ? undefined
      : {
          placement: "right",
          icon: (
            <Ic24ArrowRight
              color={theme.colors.black100 as string}
              viewBox={"0 0 24 24"}
              height={"20px"}
              width={"20px"}
            />
          )
        };

  return (
    <LoadingButton
      onClick={messageArtist}
      variant={"secondary"}
      size={rvMap(props.size, getSize)}
      disabled={false}
      loading={isInFlight}
      iconProps={iconProps}
    >
      {children}
    </LoadingButton>
  );
}

function getSize(size: MakeOfferActionSize) {
  switch (size) {
    case "small":
      return "small";
    case "large":
      return "medium";
  }
}

export default MakeOfferAction;
