import { ReactElement } from "react";
import { generatePath } from "react-router-dom";
import { Flex, Text } from "theme-ui";
import { useThemeUI } from "theme-ui";

import Ic24ArrowRight from "../../../../../imgs/icons/ic24-arrow-right.svg";
import Ic24Chat from "../../../../../imgs/icons/ic24-chat.svg";
import Ic24Suitcase from "../../../../../imgs/icons/ic24-suitcase.svg";
import Ic24TrendingUp from "../../../../../imgs/icons/ic24-trending-up.svg";
import Ic24VolumeMax from "../../../../../imgs/icons/ic24-volume-max.svg";
import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import Button, {
  ButtonSize
} from "../../../../components/01_Core/Buttons/Button";
import TextButton from "../../../../components/01_Core/Buttons/TextButton";
import { IconBox } from "../../../../components/01_Core/Foundations/Icons";
import { FixedGrid } from "../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../components/01_Core/Grids/GridItem";
import { useBreakpoint } from "../../../../utils/useBreakpoints";
import { InternalRoutes } from "../../Routing/InternalRoutes";
import ProgressBar from "../03_UI_Kit/Forms/ProgressBar";
import AccountManagementHeading from "../08_Account_Management/AccountManagementHeading";
import { dealsSubpage } from "../08_Account_Management/AccountManagementPage";
import HelperText from "./HelperText";

function getTitleVariant() {
  const breakpointMap = useBreakpoint();
  return breakpointMap.mobile ? "bodyLarge" : "subtitle";
}

function getDescriptionVariant() {
  const breakpointMap = useBreakpoint();
  return breakpointMap.mobile ? "bodySmall" : "bodyMedium";
}

function StepBox(props: {
  stepNum: number;
  Icon: SvgrComponent;
  title: string;
  description: string;
}): ReactElement {
  const breakpointMap = useBreakpoint();

  const stepNumVariant = breakpointMap.mobile ? "h500" : "h600";

  return (
    <AutoLayout
      dependentProps={{
        direction: "horizontal"
      }}
      spacing={32}
      sx={{
        width: "100%",
        alignItems: "center",
        border: "3px solid",
        borderColor: "midGray70",
        borderRadius: "16px",
        paddingX: "32px",
        paddingY: "16px"
      }}
    >
      <Text
        variant={stepNumVariant}
        sx={{ textAlign: "right", width: "0.8em" }}
      >
        {props.stepNum}.
      </Text>
      <AutoLayout
        dependentProps={{
          direction: "vertical"
        }}
        spacing={8}
      >
        <Text variant={getTitleVariant()}>{props.title}</Text>
        <Text variant={getDescriptionVariant()} color={"deepGray100"}>
          {props.description}
        </Text>
      </AutoLayout>
      <Text variant={stepNumVariant} sx={{ marginLeft: "auto" }}>
        <IconBox
          icon={props.Icon}
          sx={{ size: "1.2em", color: "deepGray100", paddingRight: "8px" }}
        />
      </Text>
    </AutoLayout>
  );
}

function GetStartedButton(props: {
  title: string;
  description: string;
  path: string;
}): ReactElement {
  const buttonSize: ButtonSize = useBreakpoint().mobile ? "medium" : "large";
  return (
    <AutoLayout
      dependentProps={{
        direction: "vertical",
        alignment: "center"
      }}
      spacing={8}
      sx={{ width: "100%" }}
    >
      <Button
        size={buttonSize}
        variant={"primary"}
        onClick={() => (window.location.href = props.path)}
        disabled={false}
        sx={{ width: "100%" }}
      >
        {props.title}
      </Button>
      <Text
        variant={getDescriptionVariant()}
        color={"deepGray100"}
        sx={{ paddingX: "16px" }}
      >
        {props.description}
      </Text>
    </AutoLayout>
  );
}

export default function ArtistTutorialPage(): ReactElement {
  const { theme } = useThemeUI();
  return (
    <GridItem
      gridColumn={"1 / -1"}
      sx={{ width: "100%", justifyContent: "center" }}
    >
      <FixedGrid>
        <GridItem
          sx={{ flexDirection: "column" }}
          gridColumn={["1 / span 4", "1 / span 8", "1 / span 8", "3 / span 8"]}
          mt={[32, 32, 64, 64]}
        >
          <ProgressBar percent={100} />
          <AutoLayout
            spacing={32}
            dependentProps={{
              direction: "vertical",
              alignment: "center"
            }}
            resizingX={FillContainer()}
            p={["16px", "32px", "88px", "88px"]}
            bg={"white100"}
            sx={{
              border: "1px solid",
              borderColor: "midGray70"
            }}
          >
            <AutoLayout spacing={48} dependentProps={{ direction: "vertical" }}>
              <AccountManagementHeading
                size={["small", "large", "large", "large"]}
                title={"Welcome to indify!"}
                description={"Start learning how the platform works."}
                moreDescription={[
                  "indify connects you with strategic partners who can help you grow your career, while ensuring you retain ownership and creative control of your work.",
                  "Partners on indify are some of the best in the industry at helping artists break and will work closely with you to take you to the next level."
                ]}
              />
              <AutoLayout
                dependentProps={{
                  direction: "vertical"
                }}
                spacing={24}
                sx={{
                  width: "100%",
                  alignItems: "center"
                }}
              >
                <StepBox
                  stepNum={1}
                  Icon={Ic24VolumeMax}
                  title={"Show off your music"}
                  description={
                    "Partners will see music you've released on Spotify. In addition, you can provide details about your upcoming releases."
                  }
                />
                <StepBox
                  stepNum={2}
                  Icon={Ic24Chat}
                  title={"Meet potential partners"}
                  description={
                    "Interested partners will message you on the platform.  You can also introduce yourself to a limited number of partners."
                  }
                />
                <StepBox
                  stepNum={3}
                  Icon={Ic24Suitcase}
                  title={"Partner makes an offer"}
                  description={
                    "Partners send offers to provide strategic value for your career (such as funding or services) in exchange for a royalty.  You can negotiate the terms of the offer."
                  }
                />
                <StepBox
                  stepNum={4}
                  Icon={Ic24TrendingUp}
                  title={"Partner adds value"}
                  description={
                    "Once you accept an offer, indify will collect streaming royalties and distribute them to you and your partner."
                  }
                />
              </AutoLayout>
              <AccountManagementHeading
                size={["small", "large", "large", "large"]}
                title={"Ready to get started?"}
                description={"Take the next step to grow your career."}
              />
              <AutoLayout
                dependentProps={{
                  direction: "horizontal"
                }}
                spacing={24}
                sx={{
                  width: "100%",
                  justifyContent: "center"
                }}
              >
                <GetStartedButton
                  title={"Propose a deal"}
                  description={
                    "Show off your released and upcoming music to potential partners."
                  }
                  path={generatePath(InternalRoutes.settings, {
                    subpage: dealsSubpage
                  })}
                />
                <GetStartedButton
                  title={"Browse partners"}
                  description={
                    "View and message potential partners who can help you grow your career."
                  }
                  path={generatePath(InternalRoutes.fundDirectory)}
                />
              </AutoLayout>
              <Flex sx={{ width: "100%", justifyContent: "center" }}>
                <TextButton
                  type={"secondary"}
                  onClick={() => (window.location.href = "/")}
                  size={"large"}
                  iconProps={{
                    placement: "right",
                    icon: (
                      <Ic24ArrowRight color={theme.colors.black100 as string} />
                    )
                  }}
                >
                  Just take me to the platform
                </TextButton>
              </Flex>
            </AutoLayout>
          </AutoLayout>
          <HelperText />
        </GridItem>
      </FixedGrid>
    </GridItem>
  );
}
