import { ReactElement } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { ResponsiveValue } from "styled-system";

import AutoLayout from "../../../../../components/01_Core/AutoLayout";
import { GridItem } from "../../../../../components/01_Core/Grids/GridItem";
import { Logo } from "../../../../../components/02_Brand_Assets/Logo";
import { navigationHeight, zIndices } from "../../../../../theme/theme";
import { useAuth } from "../../../hooks/useAuth";
import { InternalRoutes } from "../../../Routing/InternalRoutes";
import Artist from "./MainNav/Artist/Artist";
import { LoggedOutNavBar } from "./MainNav/DefaultNavBar";
import DrillIn from "./MainNav/DrillIn";
import Inbox from "./MainNav/Items/Inbox";
import UserInfo from "./MainNav/Items/UserInfo";
import Partner from "./MainNav/Partner";

function NavBarSwitch(): ReactElement {
  const { authUser } = useAuth();
  const artistProfileMatch = useRouteMatch(InternalRoutes.artistProfile);
  const artistTutorialMatch = useRouteMatch(InternalRoutes.artistTutorial);

  if (!authUser || artistTutorialMatch) {
    return <LoggedOutNavBar />;
  }

  let navBar;
  if (artistProfileMatch) {
    navBar = <DrillIn />;
  } else {
    const NavBar = authUser.userType === "artist" ? Artist : Partner;
    const size = ["small", "large", "large", "large"] as ResponsiveValue<
      "small" | "large"
    >;

    navBar = (
      <>
        <AutoLayout
          spacing={44}
          dependentProps={{ direction: "horizontal", alignment: "left" }}
          sx={{ flex: 1, height: "100%" }}
        >
          <Link
            to={
              authUser.userType === "artist"
                ? InternalRoutes.fundDirectory
                : InternalRoutes.featuredDealDirectory
            }
          >
            <Logo />
          </Link>
          <NavBar />
        </AutoLayout>
        <AutoLayout spacing={8} dependentProps={{ direction: "horizontal" }}>
          <UserInfo size={size} />
          <Inbox size={size} />
        </AutoLayout>
      </>
    );
  }

  return (
    <GridItem
      gridColumn={"1 / -1"}
      paddingLeft={"20px"}
      paddingRight={["8px", "16px", "16px", "16px"]}
      bg={"white100"}
      sx={{
        position: "sticky",
        zIndex: zIndices.nav,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: navigationHeight,
        top: 0,
        width: "100%"
      }}
    >
      {navBar}
    </GridItem>
  );
}

export default NavBarSwitch;
