import { OfferMessageShell_message$key } from "__generated__/OfferMessageShell_message.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Flex, Text } from "theme-ui";

import AutoLayout from "../../../../../../components/01_Core/AutoLayout";
import { useActiveResponsiveValue } from "../../../../../../utils/responsiveUtils";
import { useAuth } from "../../../../hooks/useAuth";
import OfferMessageBody from "./OfferMessageBody";
import OfferMessageCTAArtist from "./OfferMessageCTAArtist";
import OfferMessageCTAPartner from "./OfferMessageCTAPartner";

type OfferMessageSize = "small" | "medium";

export interface IOfferMessageProps {
  size: ResponsiveValue<OfferMessageSize>;
  message: OfferMessageShell_message$key;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?type=design&node-id=741%3A21176&t=pDWHqB6K88styCLl-1
 */
function OfferMessageShell(props: IOfferMessageProps): ReactElement {
  const message = useFragment(
    graphql`
      fragment OfferMessageShell_message on ChatMessageNode {
        offer {
          status
          ...OfferMessageCTAArtist_offer
          ...OfferMessageCTAPartner_offer
          ...OfferMessageBody_offer
        }
        chat {
          other {
            name
          }
          ...OfferMessageCTAArtist_chat
          ...OfferMessageCTAPartner_chat
        }
      }
    `,
    props.message
  );

  const { authUser } = useAuth();
  const activeSize = useActiveResponsiveValue(props.size);

  const OfferMessageCTA =
    authUser.userType === "artist"
      ? OfferMessageCTAArtist
      : OfferMessageCTAPartner;

  function getOfferHeadingText() {
    switch (authUser.userType) {
      case "partner":
        return (
          <Text>
            Offer sent to <strong>{message.chat.other.name}</strong> for review
          </Text>
        );
      case "artist":
        return (
          <Text>
            <strong>{message.chat.other.name}</strong> sent you an offer
          </Text>
        );
    }
  }

  const offerIsSent = (
    offer: typeof message.offer
  ): offer is typeof message.offer & { status: "sent" } =>
    offer.status === "sent";

  return (
    <AutoLayout
      bg={"secondary10"}
      px={"8px"}
      py={"24px"}
      sx={{
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        borderRadius: "4px"
      }}
      dependentProps={{ direction: "vertical" }}
      spacing={16}
    >
      <Text variant={"primary.bodySmall"}>{getOfferHeadingText()}</Text>
      <Flex
        bg={"white100"}
        sx={{
          width: "100%",
          flexDirection: "column",
          border: "1px solid",
          borderColor: "midGray100",
          borderRadius: "4px"
        }}
      >
        <OfferMessageBody size={activeSize} offer={message.offer} />
      </Flex>
      {offerIsSent(message.offer) && (
        <OfferMessageCTA offer={message.offer} chat={message.chat} />
      )}
    </AutoLayout>
  );
}

export default OfferMessageShell;
