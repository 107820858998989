import { ReleaseCard_release$key } from "__generated__/ReleaseCard_release.graphql";
import { ReleaseTypename } from "__generated__/UpdateOrCreateReleaseModal_Mutation.graphql";
import { Properties } from "csstype";
import { ReactElement } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { ResponsiveValue } from "styled-system";
import { Box, Flex } from "theme-ui";

import Ic20ArrowRight from "../../../../../../imgs/icons/ic20-arrow-right-1.svg";
import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import LoadingButton from "../../../../../components/01_Core/Buttons/LoadingButton";
import ReleaseInfoCardView from "../../../../../components/01_Core/Releases/ReleaseInfoCardView";
import { rvMap } from "../../../../../utils/responsiveUtils";
import { useMessageActor } from "../../../hooks/useMessage";

export type ReleaseCardSize = "small" | "large";

interface IReleaseCardDefaultProps {
  size: ResponsiveValue<ReleaseCardSize>;
  release: ReleaseCard_release$key;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=2008%3A19782
 */
function ReleaseCard(props: IReleaseCardDefaultProps): ReactElement {
  const release = useFragment(
    graphql`
      fragment ReleaseCard_release on ReleaseInterface {
        __typename
        id
        artist {
          ...useMessage_actor
        }
        __typename
        ...ReleaseInfoCardView_release
      }
    `,
    props.release
  );

  const Body = () => {
    return (
      <Box
        sx={{
          width: "100%",
          padding: rvMap(props.size, getBodyPadding)
        }}
      >
        <ReleaseInfoCardView release={release} size={props.size} />
      </Box>
    );
  };
  const [makeOffer, isInFlight] = useMessageActor(release.artist, {
    isMakingOffer: true,
    initialValues: {
      allReleases: [
        { typename: release.__typename as ReleaseTypename, id: release.id }
      ]
    }
  });
  const Footer = () => {
    return (
      <AutoLayout
        spacing={rvMap(props.size, getFooterSpacing)}
        dependentProps={{ direction: "vertical", alignment: "center" }}
        resizingX={FillContainer()}
        sx={{
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
          backgroundColor: "white100",
          padding: rvMap(props.size, getFooterPadding)
        }}
      >
        <LoadingButton
          size={rvMap(props.size, getButtonSize)}
          variant={"secondary"}
          onClick={makeOffer}
          disabled={false}
          loading={isInFlight}
          iconProps={{
            placement: "right",
            icon: <Ic20ArrowRight />
          }}
          sx={{ width: "100%" }}
        >
          Make offer
        </LoadingButton>
      </AutoLayout>
    );
  };

  return (
    <Flex
      sx={{
        flexDirection: "column",
        backgroundColor: "white100",
        border: "1px solid",
        borderColor: "midGray70",
        borderRadius: "8px",
        width: "100%"
      }}
    >
      <Body />
      <Footer />
    </Flex>
  );
}

function getButtonSize(size: ReleaseCardSize) {
  switch (size) {
    case "small":
      return "small";
    case "large":
      return "medium";
  }
}

function getFooterSpacing(size: ReleaseCardSize) {
  switch (size) {
    case "small":
      return 8;
    case "large":
      return 24;
  }
}

export function getBodyHeight(size: ReleaseCardSize): Properties["height"] {
  switch (size) {
    case "small":
      return "200px";
    case "large":
      return "280px";
  }
}

export function getBodyPadding(size: ReleaseCardSize): Properties["padding"] {
  switch (size) {
    case "small":
      return "8px";
    case "large":
      return "24px";
  }
}

function getFooterPadding(size: ReleaseCardSize) {
  switch (size) {
    case "small":
      return "16px";
    case "large":
      return "24px";
  }
}

export default ReleaseCard;
