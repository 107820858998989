/**
 * @generated SignedSource<<c9ef89840e632d766dc3db42934f24e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserType = "artist" | "partner";
import { FragmentRefs } from "relay-runtime";
export type PrivateRoute_authUser$data = {
  readonly email: string;
  readonly id: string;
  readonly name: string;
  readonly userType: UserType | null;
  readonly " $fragmentType": "PrivateRoute_authUser";
};
export type PrivateRoute_authUser$key = {
  readonly " $data"?: PrivateRoute_authUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"PrivateRoute_authUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PrivateRoute_authUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};

(node as any).hash = "7b44446641190fc944e65c7ca3f61f5d";

export default node;
