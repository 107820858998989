import { ReactElement } from "react";
import { Redirect } from "react-router-dom";

import { UserType } from "../../../types/users";
import { useAuth } from "../hooks/useAuth";
import { InternalRoutes } from "./InternalRoutes";

function DefaultPrivatePage(): ReactElement {
  const { authUser } = useAuth();

  if (!authUser) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location.pathname }
        }}
      />
    );
  }

  const defaultRoutes: {
    [userType in UserType]: (typeof InternalRoutes)[keyof typeof InternalRoutes];
  } = {
    artist: InternalRoutes.fundDirectory,
    partner: InternalRoutes.featuredDealDirectory
  };

  return <Redirect to={defaultRoutes[authUser.userType]} />;
}

export default DefaultPrivatePage;
