/**
 * @generated SignedSource<<bcb2a9c995c89e07088b03c80cf290fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OfferStatus = "accepted" | "active" | "canceled" | "declined" | "earnings_directed" | "funds_received" | "sent" | "stripe_charge_created" | "voided";
import { FragmentRefs } from "relay-runtime";
export type OfferMessageCTAPartner_offer$data = {
  readonly artist: {
    readonly displayName: string;
  };
  readonly id: string;
  readonly status: OfferStatus;
  readonly " $fragmentType": "OfferMessageCTAPartner_offer";
};
export type OfferMessageCTAPartner_offer$key = {
  readonly " $data"?: OfferMessageCTAPartner_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"OfferMessageCTAPartner_offer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OfferMessageCTAPartner_offer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ArtistNode",
      "kind": "LinkedField",
      "name": "artist",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OfferNode",
  "abstractKey": null
};

(node as any).hash = "003575ea163e1bf403c6e300ba3ef830";

export default node;
