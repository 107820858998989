import { OfferMessageBody_offer$key } from "__generated__/OfferMessageBody_offer.graphql";
import { ReactElement, useState } from "react";
import { graphql, useFragment } from "react-relay";
import { Box, Divider, Flex, Image, Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../../../components/01_Core/AutoLayout";
import { centsToDollars, formatDollars } from "../../../../../../utils/money";
import { useAuth } from "../../../../hooks/useAuth";
import { IFundingJsonTerms } from "../../../07_Deal_Flow/OfferFormFunding";
import OfferPartnership from "../../Offers/OfferPartnership";
import {
  getPostRecoupSplitWarning,
  getTermLengthWarning
} from "../../Offers/OfferTermsWarningBlock";
import OfferNextStep from "../OfferNextStep/OfferNextStep";
import OfferMessageDefinitions from "./OfferMessageDefinitions";
import OfferMessageHeading from "./OfferMessageHeading";
import OfferMessageReleasesIncluded from "./OfferMessageReleasesIncluded";
import OfferMessageSection from "./OfferMessageSection";
import OfferMessageShowDefinitions from "./OfferMessageShowDefinitions";

export type OfferMessageBodySize = "small" | "medium";

interface IOfferMessageBodyProps {
  offer: OfferMessageBody_offer$key;
  size: OfferMessageBodySize;
  showDefinitionsByDefault?: boolean;
}

const spendWarning =
  "make sure that you are in agreement about when funds will be spent and reported.";
export const spendWarnings = {
  partner: `Because you will spend money on behalf of the artist, ${spendWarning}`,
  artist: `Because the partner will spend money on your behalf, ${spendWarning}`
};

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=741%3A20293
 */
function OfferMessageBody(props: IOfferMessageBodyProps): ReactElement {
  const offer = useFragment(
    graphql`
      fragment OfferMessageBody_offer on OfferNode {
        dateOfferAccepted
        status
        terms
        partnerSignature
        artistSignature
        willTransferCreativeAdvance
        willTransferMarketingAdvance
        releases {
          edges {
            node {
              ...OfferMessageReleasesIncluded_releases
            }
          }
        }
        unreleasedReleases {
          edges {
            node {
              ...OfferMessageReleasesIncluded_unreleasedReleases
            }
          }
        }
        artist {
          displayName
          ...OfferPartnership_artist
        }
        partner {
          displayName
          ...OfferPartnership_partner
        }
        ...OfferNextStep_offer
        ...OfferMessageReleasesIncluded_offer
      }
    `,
    props.offer
  );

  const [areDefinitionsShown, setAreDefinitionsShown] = useState(
    props.showDefinitionsByDefault ?? true
  );
  const toggleAreDefinitionsShown = () =>
    setAreDefinitionsShown(!areDefinitionsShown);

  const terms = JSON.parse(offer.terms) as IFundingJsonTerms;
  const advanceTotal =
    (terms.advanceAmountCents ?? 0) +
    (terms.marketingAmountCents ?? 0) +
    (terms.creativeAmountCents ?? 0);

  const formattedArtistName = <strong>{offer.artist.displayName}</strong>;
  const formattedPartnerName = <strong>{offer.partner.displayName}</strong>;

  const getTransferDestinationText = (willTransfer: boolean) => {
    return willTransfer ? (
      <Text>, to be sent directly to {formattedArtistName}</Text>
    ) : (
      <Text>
        , to be spent by {formattedPartnerName} on behalf of $
        {formattedArtistName}
      </Text>
    );
  };

  // Don't show warnings if the offer has been accepted
  const canShowWarnings = !offer.dateOfferAccepted;
  const { userType } = useAuth().authUser;

  const partnerSpendAmountCents =
    (offer.willTransferCreativeAdvance ? 0 : terms.creativeAmountCents) +
    (offer.willTransferMarketingAdvance ? 0 : terms.marketingAmountCents);

  return (
    <Flex bg={"white100"} sx={{ width: "100%", flexDirection: "column" }}>
      <OfferMessageHeading size={props.size} />
      <OfferPartnership
        styles={{
          padding: "36px 0"
        }}
        artist={offer.artist}
        partner={offer.partner}
      />
      {!["sent", "canceled", "declined"].includes(offer.status) && (
        <Box sx={{ padding: "24px" }}>
          <OfferNextStep offer={offer} />
        </Box>
      )}
      <OfferMessageReleasesIncluded
        offer={offer}
        releases={offer.releases.edges.map(e => e.node)}
        unreleasedReleases={offer.unreleasedReleases.edges.map(e => e.node)}
        size={props.size}
      />
      {areDefinitionsShown && (
        <OfferMessageDefinitions
          size={props.size}
          definition={
            "All content which will be covered by the terms of this agreement."
          }
        />
      )}
      <Divider />
      <OfferMessageSection
        size={props.size}
        heading={"Advance"}
        description={
          <AutoLayout spacing={8} dependentProps={{ direction: "vertical" }}>
            <Text variant={"primary.bodyMedium"}>
              ${formatDollars(centsToDollars(advanceTotal))} Total
            </Text>
            {terms.advanceAmountCents ? (
              <Text variant={"secondary.bodySmall"}>
                • ${formatDollars(centsToDollars(terms.advanceAmountCents))}{" "}
                in-pocket for day-to-day living expenses
                {getTransferDestinationText(true)}
              </Text>
            ) : null}
            {terms.marketingAmountCents ? (
              <Text variant={"secondary.bodySmall"}>
                • ${formatDollars(centsToDollars(terms.marketingAmountCents))}{" "}
                marketing budget for promotional expenses
                {getTransferDestinationText(offer.willTransferMarketingAdvance)}
              </Text>
            ) : null}
            {terms.creativeAmountCents ? (
              <Text variant={"secondary.bodySmall"}>
                • ${formatDollars(centsToDollars(terms.creativeAmountCents))}{" "}
                creative budget for production costs
                {getTransferDestinationText(offer.willTransferCreativeAdvance)}
              </Text>
            ) : null}
          </AutoLayout>
        }
      />
      {areDefinitionsShown && (
        <OfferMessageDefinitions
          size={props.size}
          warning={{
            type: "info",
            children:
              canShowWarnings &&
              partnerSpendAmountCents > 0 &&
              spendWarnings[userType]
          }}
          definition={
            <Text>
              The in-pocket advance amount can be used on anything{" "}
              {formattedArtistName} sees fit. Any marketing or creative budgets
              refer to the amount earmarked specifically for their described
              purposes.
            </Text>
          }
        />
      )}
      <Divider />
      <OfferMessageSection
        size={props.size}
        heading={"Splits"}
        description={
          <AutoLayout
            spacing={[16, 0, 0, 0]}
            dependentProps={[
              { direction: "vertical" },
              { direction: "horizontal" },
              { direction: "horizontal" },
              { direction: "horizontal" }
            ]}
            resizingX={FillContainer()}
          >
            {terms.splitBeforeRecoupment && (
              <AutoLayout
                spacing={8}
                dependentProps={{ direction: "vertical" }}
                resizingX={FillContainer()}
              >
                <Text variant={"primary.bodyMedium"}>Pre-Recoupment</Text>
                <Text variant={"secondary.bodySmall"}>
                  • {terms.splitBeforeRecoupment.partnerPercent}% for partner
                </Text>
              </AutoLayout>
            )}
            <AutoLayout
              spacing={8}
              dependentProps={{ direction: "vertical" }}
              resizingX={FillContainer()}
            >
              {terms.splitBeforeRecoupment ? (
                <>
                  <Text variant={"primary.bodyMedium"}>Post-Recoupment</Text>
                  <Text variant={"secondary.bodySmall"}>
                    • {terms.splitAfterRecoupment.partnerPercent}% for partner
                  </Text>
                </>
              ) : (
                <Text variant={"primary.bodyMedium"}>
                  {terms.splitAfterRecoupment.partnerPercent}% for partner
                </Text>
              )}
            </AutoLayout>
          </AutoLayout>
        }
      />
      {areDefinitionsShown && (
        <OfferMessageDefinitions
          size={props.size}
          showLinkToStandardDealTerms={true}
          warning={{
            type: "warning",
            children:
              canShowWarnings &&
              getPostRecoupSplitWarning(
                terms.splitAfterRecoupment.partnerPercent
              )
          }}
          definition={
            <AutoLayout spacing={8} dependentProps={{ direction: "vertical" }}>
              <Text>
                <strong>Splits</strong> are based on 100% of gross earnings from
                streams and digital sales.
              </Text>
              {terms.splitBeforeRecoupment && (
                <Text>
                  <strong>Pre-recoupment</strong> refers to how revenue is
                  divided up <strong>until</strong> the initial advances are
                  recovered by {formattedPartnerName}.
                </Text>
              )}
              {terms.splitBeforeRecoupment && (
                <Text>
                  <strong>Post-recoupment</strong> refers to how revenue is
                  divided up <strong>after</strong> the initial advances are
                  recovered by {formattedPartnerName}.
                </Text>
              )}
            </AutoLayout>
          }
        />
      )}
      <Divider />
      <OfferMessageSection
        size={props.size}
        heading={"Term Length"}
        description={`${terms.termLength} months`}
      />
      {areDefinitionsShown && (
        <OfferMessageDefinitions
          size={props.size}
          showLinkToStandardDealTerms={true}
          warning={{
            type: "warning",
            children:
              canShowWarnings &&
              getTermLengthWarning(terms.termLength, userType)
          }}
          definition={
            <AutoLayout spacing={8} dependentProps={{ direction: "vertical" }}>
              <Text>
                The term is the period during which the partner will receive a
                split of the earnings.
              </Text>
              <Text>
                The term begins when the deal has been signed by both parties
                and ends after {terms.termLength} months.
              </Text>
              <Text>
                If there is any unreleased content included, the term length
                instead ends {terms.termLength} months after all content
                included has been commercially released.
              </Text>
              <Text>
                Splits are applied immediately upon the start of the term. After
                the end of the term, the artist will receive 100% of earnings,
                unless a new deal is signed.
              </Text>
            </AutoLayout>
          }
        />
      )}
      <Divider />
      {(offer.partnerSignature || offer.artistSignature) && (
        <OfferMessageSection
          size={props.size}
          heading={"Signatures"}
          description={
            <AutoLayout
              spacing={32}
              dependentProps={{ direction: "horizontal" }}
              resizingX={FillContainer()}
              pt={2}
            >
              {offer.partnerSignature && (
                <AutoLayout
                  spacing={8}
                  dependentProps={{ direction: "vertical" }}
                  resizingX={FillContainer()}
                  sx={{ alignSelf: "flex-end" }}
                >
                  <Image
                    src={offer.partnerSignature}
                    sx={{ maxHeight: "85px", width: "auto" }}
                  />
                  <Text variant={"secondary.bodySmall"}>Partner Signature</Text>
                </AutoLayout>
              )}
              {offer.artistSignature && (
                <AutoLayout
                  spacing={8}
                  dependentProps={{ direction: "vertical" }}
                  resizingX={FillContainer()}
                  sx={{ alignSelf: "flex-end" }}
                >
                  <Image
                    src={offer.artistSignature}
                    sx={{ maxHeight: "85px", width: "auto" }}
                  />
                  <Text variant={"secondary.bodySmall"}>Artist Signature</Text>
                </AutoLayout>
              )}
            </AutoLayout>
          }
        />
      )}
      <OfferMessageShowDefinitions
        size={props.size}
        areDefinitionsShown={areDefinitionsShown}
        onClick={toggleAreDefinitionsShown}
      />
    </Flex>
  );
}

export default OfferMessageBody;
