import { PrivateRoute_authUser$key } from "__generated__/PrivateRoute_authUser.graphql";
import { UserType } from "__generated__/useAuth_MeFragment.graphql";
import {
  configureScope as configureSentryScope,
  setUser as setSentryUser
} from "@sentry/react";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { Redirect, RouteProps } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";
import { SentryRoute } from "./Routing";

function PrivateRoute(
  props: { forUserType?: UserType } & RouteProps
): ReactElement {
  const authUser = useFragment<PrivateRoute_authUser$key>(
    graphql`
      fragment PrivateRoute_authUser on UserNode {
        id
        email
        userType
        name
      }
    `,
    useAuth().authUser
  );

  if (!authUser) {
    configureSentryScope(scope => scope.setUser(null));

    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location.pathname }
        }}
      />
    );
  }

  setSentryUser({
    id: authUser.id,
    email: authUser.email,
    username: authUser.name
  });

  if (props.forUserType && authUser.userType !== props.forUserType) {
    return <Redirect to={"/"} />;
  }

  return <SentryRoute {...props} />;
}

export default PrivateRoute;
