/**
 * @generated SignedSource<<593cce1a2a81823c79f609769ac0eadd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OfferNextStep_offer$data = {
  readonly artist: {
    readonly displayName: string;
  };
  readonly moneyTransferAmountCents: number | null;
  readonly partner: {
    readonly displayName: string;
  };
  readonly stripeSourceInfo: any | null;
  readonly transferRecipient: {
    readonly user: {
      readonly id: string;
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"useOfferNextStep_offer">;
  readonly " $fragmentType": "OfferNextStep_offer";
};
export type OfferNextStep_offer$key = {
  readonly " $data"?: OfferNextStep_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"OfferNextStep_offer">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OfferNextStep_offer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "moneyTransferAmountCents",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stripeSourceInfo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PartnerNode",
      "kind": "LinkedField",
      "name": "partner",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ArtistNode",
      "kind": "LinkedField",
      "name": "artist",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "transferRecipient",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserNode",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOfferNextStep_offer"
    }
  ],
  "type": "OfferNode",
  "abstractKey": null
};
})();

(node as any).hash = "8e59c3f008e80a9e5dc0c62066ee053f";

export default node;
