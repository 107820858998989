import { BasicInfoFormArtist_artist$key } from "__generated__/BasicInfoFormArtist_artist.graphql";
import {
  ArtistPartnershipStatus,
  ArtistSeekingServiceService,
  BasicInfoFormArtistMutation
} from "__generated__/BasicInfoFormArtistMutation.graphql";
import { setUser as setSentryUser } from "@sentry/react";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import { ReactElement, useContext } from "react";
import { Form, FormRenderProps, useFormState } from "react-final-form";
import { graphql, useFragment, useMutation } from "react-relay";
import { PayloadError } from "relay-runtime";

import { BannerContext } from "../../../../components/01_Core/Announcements/BannerProvider";
import SelectInput from "../../../../components/01_Core/Forms/Inputs/SelectInput";
import { Field } from "../../../../components/01_Core/Forms/utils/reactFinalFormWrappers";
import Divider from "../../../../components/01_Core/Miscelleneous/Divider";
import { useAuth } from "../../hooks/useAuth";
import SectionProfilePhoto from "../03_UI_Kit/Forms/SectionProfilePhoto";
import AccountManagementFormContent from "./AccountManagementFormContent";
import AccountManagementHeading from "./AccountManagementHeading";
import Footer from "./Footer";
import About from "./FormFields/About";
import DisplayName from "./FormFields/DisplayName";
import Genre from "./FormFields/Genre";
import Location from "./FormFields/Location";
import Slug, { slugFieldName } from "./FormFields/Slug";

type IBasicInfoFormArtistValues = Omit<
  BasicInfoFormArtistMutation["variables"]["input"],
  "artistNodeId" | "genreNodeId" | "partnershipStatus" | "seekingServices"
> & {
  genre: { value: string };
  partnershipStatus: { value: ArtistPartnershipStatus };
  seekingServices: { value: ArtistSeekingServiceService }[];
};

/**
 * figma: https://www.figma.com/file/IF1kneOJMIUGtuGuRnMDqn/08-Account-Management?node-id=1472%3A13538
 */
function BasicInfoFormArtist(props: {
  artist: BasicInfoFormArtist_artist$key;
}): ReactElement {
  const notifyBanner = useContext(BannerContext);
  // TODO: Move query properties into fragments for each field.
  const artist = useFragment(
    graphql`
      fragment BasicInfoFormArtist_artist on ArtistNode {
        id
        thumbnailUrl
        displayName
        location
        about
        genre {
          id
        }
        visible
        slug
        partnershipStatus
        seekingServices {
          edges {
            node {
              service
            }
          }
        }
      }
    `,
    props.artist
  );

  const { authUser } = useAuth();

  const [commit] = useMutation<BasicInfoFormArtistMutation>(graphql`
    mutation BasicInfoFormArtistMutation(
      $input: EditArtistBasicInfoMutationInput!
    ) {
      editArtistBasicInfo(data: $input) {
        artist {
          displayName
          user {
            id
            email
          }
          ...BasicInfoFormArtist_artist
        }
      }
    }
  `);

  const onSubmit = (values: IBasicInfoFormArtistValues) => {
    return new Promise(resolve => {
      const resolveUnknownError = () =>
        resolve({ [FORM_ERROR]: "Something went wrong." });

      const { genre, partnershipStatus, seekingServices, ...rest } = values;
      commit({
        variables: {
          input: {
            artistNodeId: artist.id,
            genreNodeId: genre?.value,
            partnershipStatus: partnershipStatus?.value,
            seekingServices: seekingServices?.map(({ value }) => value),
            ...rest
          }
        },
        onCompleted(data, errors) {
          if (
            errors != null &&
            // TODO: better error recog
            _.some(
              errors,
              (e: PayloadError) =>
                e.message.includes("Duplicate entry") &&
                e.message.includes("slug")
            )
          ) {
            return resolve({
              [slugFieldName]: "That profile URL is already taken."
            });
          }
          if (errors != null && errors.length) {
            return resolveUnknownError();
          }

          notifyBanner({
            type: "neutral",
            children: "Your basic info has been updated."
          });

          const {
            editArtistBasicInfo: {
              artist: {
                displayName: username,
                user: { id, email }
              }
            }
          } = data;
          setSentryUser({ id, email, username });

          return resolve("success");
        },
        onError: () => {
          return resolveUnknownError();
        }
      });
    });
  };

  const {
    id,
    thumbnailUrl,
    displayName,
    location,
    about,
    partnershipStatus,
    seekingServices,
    genre,
    visible,
    slug
  } = artist;
  const initialValues: IBasicInfoFormArtistValues = {
    displayName,
    genre: { value: genre?.id },
    partnershipStatus: { value: partnershipStatus },
    seekingServices: seekingServices?.edges.map(({ node }) => ({
      value: node.service
    })),
    location,
    about,
    visible,
    slug
  };

  function PartnershipStatus() {
    const options = _.map(
      GLOBALS.ARTIST_PARTNERSHIP_STATUSES,
      ({ displayName: label }, value) => ({ label, value })
    );
    return (
      <Field<(typeof options)[number]> name={"partnershipStatus"}>
        {({ input, meta }) => {
          const currentValue = options.find(o => input.value.value === o.value);
          return (
            <SelectInput
              size={["medium", "large", "large", "large"]}
              label={"Partnership Status"}
              input={{ ...input, value: currentValue }}
              meta={meta}
              options={options}
            />
          );
        }}
      </Field>
    );
  }

  function SeekingServices() {
    const options = _.map(
      GLOBALS.PARTNER_SERVICES,
      ({ displayName: label }, value) => ({ label, value })
    );

    const seekingServicesSelected =
      useFormState<IBasicInfoFormArtistValues>().values.seekingServices || [];
    return (
      <Field<typeof options> name={"seekingServices"}>
        {({ input, meta }) => {
          const currentValue = options.filter(o =>
            input.value.map(({ value }) => value).includes(o.value)
          );
          return (
            <SelectInput
              size={["medium", "large", "large", "large"]}
              label={"Seeking Services"}
              input={{ ...input, value: currentValue }}
              meta={meta}
              options={options}
              isOptionDisabled={() => seekingServicesSelected.length >= 3}
              caption={
                "Select up to 3 services you'd like to receive from partners."
              }
              isMulti={true}
            />
          );
        }}
      </Field>
    );
  }

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit}>
      {(formProps: FormRenderProps<IBasicInfoFormArtistValues>) => {
        return (
          <form onSubmit={formProps.handleSubmit} style={{ width: "100%" }}>
            <AccountManagementFormContent>
              <AccountManagementHeading
                key={"heading"}
                size={["small", "large", "large", "large"]}
                title={"Basic Info"}
                description={"Let the indify community know a bit about you."}
              />
              <SectionProfilePhoto
                ctaText={"Upload new photo"}
                thumbnailUrl={thumbnailUrl}
                nodeId={id}
                size={["small", "medium", "large", "large"]}
              />
              <DisplayName />
              <Slug kind={"artist"} />
              <Divider />
              <PartnershipStatus />
              <SeekingServices />
              <Genre fieldName="genre" label="Genre" isMulti={false} />
              <Location />
              <About label={"What You Do"} />
              <Footer
                buttonDisabled={
                  formProps.pristine || formProps.hasValidationErrors
                }
                onClick={formProps.handleSubmit}
                size={["small", "large", "large", "large"]}
                loading={formProps.submitting}
              />
            </AccountManagementFormContent>
          </form>
        );
      }}
    </Form>
  );
}

export default BasicInfoFormArtist;
