import { BasicInfoFormPartner_partner$key } from "__generated__/BasicInfoFormPartner_partner.graphql";
import { BasicInfoFormPartnerMutation } from "__generated__/BasicInfoFormPartnerMutation.graphql";
import { setUser as setSentryUser } from "@sentry/react";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import { ReactElement, useContext } from "react";
import { Form, FormRenderProps } from "react-final-form";
import { graphql, useFragment, useMutation } from "react-relay";
import { PayloadError } from "relay-runtime";

import { BannerContext } from "../../../../components/01_Core/Announcements/BannerProvider";
import SectionProfilePhoto from "../03_UI_Kit/Forms/SectionProfilePhoto";
import AccountManagementFormContent from "./AccountManagementFormContent";
import AccountManagementHeading from "./AccountManagementHeading";
import { IEntityInfoValues } from "./AccountManagementPage";
import Footer from "./Footer";
import DisplayName from "./FormFields/DisplayName";
import Location from "./FormFields/Location";
import Slug, { slugFieldName } from "./FormFields/Slug";

export type IBasicInfoFormPartnerValues = IEntityInfoValues;

/**
 * figma: https://www.figma.com/file/IF1kneOJMIUGtuGuRnMDqn/08-Account-Management?node-id=1368%3A14073
 */
function BasicInfoFormPartner(props: {
  partner: BasicInfoFormPartner_partner$key;
}): ReactElement {
  const notifyBanner = useContext(BannerContext);
  const partner = useFragment(
    graphql`
      fragment BasicInfoFormPartner_partner on PartnerNode {
        id
        thumbnailUrl
        displayName
        location
        about
        slug
      }
    `,
    props.partner
  );
  const [commit] = useMutation<BasicInfoFormPartnerMutation>(graphql`
    mutation BasicInfoFormPartnerMutation(
      $input: EditPartnerBasicInfoMutationInput!
    ) {
      editPartnerBasicInfo(data: $input) {
        partner {
          displayName
          user {
            id
            email
          }
          ...BasicInfoFormPartner_partner
        }
      }
    }
  `);
  const onSubmit = (values: IBasicInfoFormPartnerValues) => {
    return new Promise(resolve => {
      const resolveUnknownError = () =>
        resolve({ [FORM_ERROR]: "Something went wrong." });

      commit({
        variables: {
          input: { partnerNodeId: partner.id, slug: partner.slug, ...values }
        },
        onCompleted(data, errors) {
          if (
            // TODO: better error recog
            _.some(
              errors,
              (e: PayloadError) =>
                e.message.includes("Duplicate entry") &&
                e.message.includes("slug")
            )
          ) {
            return resolve({
              [slugFieldName]: "That profile URL is already taken."
            });
          }
          if (errors != null && errors.length) {
            return resolveUnknownError();
          }

          notifyBanner({
            type: "neutral",
            children: "Your basic info has been updated."
          });

          const {
            editPartnerBasicInfo: {
              partner: {
                displayName: username,
                user: { id, email }
              }
            }
          } = data;
          setSentryUser({ id, email, username });

          return resolve("success");
        },
        onError() {
          return resolveUnknownError();
        }
      });
    });
  };
  const { id, thumbnailUrl, displayName, slug, location, about } = partner;
  const initialValues = {
    displayName,
    slug,
    location,
    about
  };

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit}>
      {(formProps: FormRenderProps<IBasicInfoFormPartnerValues>) => {
        return (
          <form onSubmit={formProps.handleSubmit} style={{ width: "100%" }}>
            <AccountManagementFormContent>
              <AccountManagementHeading
                key={"heading"}
                size={["small", "large", "large", "large"]}
                title={"Basic Info"}
                description={"Let the indify community know a bit about you."}
              />
              <SectionProfilePhoto
                ctaText={"Upload new photo"}
                thumbnailUrl={thumbnailUrl}
                nodeId={id}
                size={["medium", "large", "large", "large"]}
              />
              <DisplayName />
              <Slug kind={"partner"} />
              <Location />
              <Footer
                buttonDisabled={
                  formProps.pristine || formProps.hasValidationErrors
                }
                onClick={formProps.handleSubmit}
                size={["small", "large", "large", "large"]}
                loading={formProps.submitting}
              />
            </AccountManagementFormContent>
          </form>
        );
      }}
    </Form>
  );
}

export default BasicInfoFormPartner;
