import { AccountManagementPageQuery as AccountManagementPageQueryType } from "__generated__/AccountManagementPageQuery.graphql";
import { ArtistDirectoryQuery as ArtistDirectoryQueryType } from "__generated__/ArtistDirectoryQuery.graphql";
import { ArtistPortfolioPageQuery as ArtistPortfolioPageQueryType } from "__generated__/ArtistPortfolioPageQuery.graphql";
import { ArtistProfileQuery as ArtistProfileQueryType } from "__generated__/ArtistProfileQuery.graphql";
import { ChatPageChatsQuery as ChatPageChatsQueryType } from "__generated__/ChatPageChatsQuery.graphql";
import { ChatThreadQuery as ChatThreadQueryType } from "__generated__/ChatThreadQuery.graphql";
import { ClaimYourAccountFlowQuery as ClaimYourAccountFlowQueryType } from "__generated__/ClaimYourAccountFlowQuery.graphql";
import { FeaturedDealDirectoryQuery as FeaturedDealDirectoryQueryType } from "__generated__/FeaturedDealDirectoryQuery.graphql";
import { FeaturedDealPageQuery as FeaturedDealPageQueryType } from "__generated__/FeaturedDealPageQuery.graphql";
import { FundCaseStudyOfferTrackDataQuery as FundCaseStudyOfferTrackDataQueryType } from "__generated__/FundCaseStudyOfferTrackDataQuery.graphql";
import { FundDirectoryQuery as FundDirectoryQueryType } from "__generated__/FundDirectoryQuery.graphql";
import { FundPortfolioPageQuery as FundPortfolioPageQueryType } from "__generated__/FundPortfolioPageQuery.graphql";
import { FundProfileQuery as FundProfileQueryType } from "__generated__/FundProfileQuery.graphql";
import { HomebaseQuery as HomebaseQueryType } from "__generated__/HomebaseQuery.graphql";
import { Routing_ArtistChartsQuery as ArtistChartsQueryType } from "__generated__/Routing_ArtistChartsQuery.graphql";
import { SpecialDealsQuery as SpecialDealsQueryType } from "__generated__/SpecialDealsQuery.graphql";
import { useAuthMeQuery as useAuthMeQueryType } from "__generated__/useAuthMeQuery.graphql";
import { withSentryRouting } from "@sentry/react";
import { createBrowserHistory } from "history";
import { ReactElement, Suspense } from "react";
import { graphql, loadQuery } from "react-relay";
import { Route, Router, Switch } from "react-router-dom";
import { Flex } from "theme-ui";

import { Loader } from "../../../components/01_Core/999_Miscellaneous/Loader";
import BannerProvider from "../../../components/01_Core/Announcements/BannerProvider";
import { FluidGrid } from "../../../components/01_Core/Grids/Grid";
import environment from "../../../environment";
import ArtistTutorialPage from "../components/01_first-time_ux/ArtistTutorialPage";
import ClaimYourAccountFlow, {
  ClaimYourAccountFlowQuery
} from "../components/01_first-time_ux/ClaimYourAccountFlow";
import NewAppFeatures from "../components/01_first-time_ux/NewAppFeatures";
import ArtistDirectory, {
  ArtistDirectoryQuery
} from "../components/03_Discover/ArtistDirectory";
import FeaturedDealDirectory, {
  FeaturedDealDirectoryQuery
} from "../components/03_Discover/FeaturedDealDirectory";
import FundDirectory, {
  FundDirectoryQuery
} from "../components/03_Discover/FundDirectory";
import { SpecialDealsQuery } from "../components/03_UI_Kit/Deals/SpecialDeals";
import { ChatThreadQuery } from "../components/03_UI_Kit/Messaging/ChatThread";
import NavBarSwitch from "../components/03_UI_Kit/Navigation/NavBarSwitch";
import ArtistProfile, {
  ArtistProfileQuery
} from "../components/04_User_Profile/ArtistProfile";
import { FundCaseStudyOfferTrackDataQuery } from "../components/04_User_Profile/FundCaseStudyOffer";
import FundProfile, {
  FundProfileQuery
} from "../components/04_User_Profile/FundProfile";
import ChatPage, {
  ChatPageChatsQuery
} from "../components/05_Messaging/ChatPage";
import FeaturedDealPage, {
  FeaturedDealPageQuery
} from "../components/06_Deal_Merchandising/FeaturedDealPage";
import AccountManagementPage, {
  AccountManagementPageQuery
} from "../components/08_Account_Management/AccountManagementPage";
import ActivateForm from "../components/08_Account_Management/login/ActivateForm";
import ForgotPasswordForm from "../components/08_Account_Management/login/ForgotPasswordForm";
import LoginForm from "../components/08_Account_Management/login/LoginForm";
import LoginPage from "../components/08_Account_Management/login/LoginPage";
import ArtistPortfolioPage, {
  ArtistPortfolioPageQuery
} from "../components/09_Payments/ArtistPortfolioPage";
import FundPortfolioPage, {
  FundPortfolioPageQuery
} from "../components/09_Payments/FundPortfolioPage";
import Homebase, { HomebaseQuery } from "../components/10_Homebase/Homebase";
import { meQuery, ProvideAuth, useAuth } from "../hooks/useAuth";
import { ProvideStaticData } from "../hooks/useStaticData";
import DefaultPrivatePage from "./DefaultPrivatePage";
import { InternalRoutes } from "./InternalRoutes";
import PrivateRoute from "./PrivateRoute";

export const Fallback = (
  <Flex
    sx={{
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      width: "100%",
      height: "100%"
    }}
  >
    <Loader />
  </Flex>
);

export const history = createBrowserHistory();

export const SentryRoute = withSentryRouting(Route);

export const ArtistChartsQuery = graphql`
  query Routing_ArtistChartsQuery($slug: String!) {
    artistBySlug(slug: $slug) {
      ...ArtistCharts_artist
    }
  }
`;

function Routes(): ReactElement {
  const { authUser } = useAuth();
  return (
    <FluidGrid
      noOuterPadding={true}
      bg={"lightGray100"}
      sx={{
        // gridTemplateRows: navigationHeight.map(h => `${h}px`),
        gridAutoRows: "min-content",
        height: "100vh"
      }}
    >
      <NavBarSwitch />
      <NewAppFeatures />
      <Suspense fallback={Fallback}>
        <BannerProvider>
          <Switch>
            <SentryRoute path={"/login"}>
              <LoginPage>
                <LoginForm />
              </LoginPage>
            </SentryRoute>
            <SentryRoute path={"/activate/:activationKey"}>
              <LoginPage>
                <ActivateForm />
              </LoginPage>
            </SentryRoute>
            <SentryRoute path={"/forgot_password"}>
              <LoginPage>
                <ForgotPasswordForm />
              </LoginPage>
            </SentryRoute>
            <SentryRoute
              path={InternalRoutes.referrals}
              render={({
                match: {
                  params: { slug }
                }
              }) => {
                const queryRef = loadQuery<ClaimYourAccountFlowQueryType>(
                  environment,
                  ClaimYourAccountFlowQuery,
                  { slug }
                );

                return (
                  <ClaimYourAccountFlow
                    referralSlug={slug}
                    queryRef={queryRef}
                  />
                );
              }}
            />
            <SentryRoute
              path={InternalRoutes.artistTutorial}
              render={() => {
                return <ArtistTutorialPage />;
              }}
            />
            <SentryRoute
              path={InternalRoutes.featuredDeal}
              render={({
                match: {
                  params: { dealId }
                }
              }) => {
                const queryRef = loadQuery<FeaturedDealPageQueryType>(
                  environment,
                  FeaturedDealPageQuery,
                  { dealId }
                );

                return <FeaturedDealPage queryRef={queryRef} />;
              }}
            />
            <PrivateRoute
              path={InternalRoutes.inbox}
              render={({
                match: {
                  params: { chatId }
                }
              }) => {
                const myChatsQueryRef = loadQuery<ChatPageChatsQueryType>(
                  environment,
                  ChatPageChatsQuery,
                  {}
                );

                const chatQueryRef = chatId
                  ? loadQuery<ChatThreadQueryType>(
                      environment,
                      ChatThreadQuery,
                      { chatId: decodeURIComponent(chatId) }
                    )
                  : null;

                return (
                  <ChatPage
                    myChatsQueryRef={myChatsQueryRef}
                    chatQueryRef={chatQueryRef}
                  />
                );
              }}
            />
            <PrivateRoute
              path={InternalRoutes.artistDirectory}
              exact={true}
              forUserType={"partner"}
              render={() => {
                const queryRef = loadQuery<ArtistDirectoryQueryType>(
                  environment,
                  ArtistDirectoryQuery,
                  {}
                );

                return <ArtistDirectory queryRef={queryRef} />;
              }}
            />
            <PrivateRoute
              path={InternalRoutes.artistProfile}
              forUserType={"partner"}
              render={({
                match: {
                  params: { artistSlug }
                }
              }) => {
                const artistProfileQueryRef = loadQuery<ArtistProfileQueryType>(
                  environment,
                  ArtistProfileQuery,
                  { slug: artistSlug }
                );
                const chartQueryRef = loadQuery<ArtistChartsQueryType>(
                  environment,
                  ArtistChartsQuery,
                  { slug: artistSlug }
                );

                return (
                  <ArtistProfile
                    queryRef={artistProfileQueryRef}
                    chartQueryRef={chartQueryRef}
                  />
                );
              }}
            />
            <PrivateRoute
              path={InternalRoutes.featuredDealDirectory}
              forUserType={"partner"}
              exact={true}
              render={() => {
                const queryRef = loadQuery<FeaturedDealDirectoryQueryType>(
                  environment,
                  FeaturedDealDirectoryQuery,
                  {}
                );

                const specialDealsQueryRef = loadQuery<SpecialDealsQueryType>(
                  environment,
                  SpecialDealsQuery,
                  {}
                );

                return (
                  <FeaturedDealDirectory
                    queryRef={queryRef}
                    specialDealsQueryRef={specialDealsQueryRef}
                  />
                );
              }}
            />
            <PrivateRoute
              path={InternalRoutes.settings}
              render={() => {
                const queryRef = loadQuery<AccountManagementPageQueryType>(
                  environment,
                  AccountManagementPageQuery,
                  {}
                );

                return <AccountManagementPage queryRef={queryRef} />;
              }}
            />
            <PrivateRoute
              path={InternalRoutes.portfolio}
              exact={true}
              render={() => {
                switch (authUser.userType) {
                  case "partner": {
                    const queryRef = loadQuery<FundPortfolioPageQueryType>(
                      environment,
                      FundPortfolioPageQuery,
                      {}
                    );

                    return <FundPortfolioPage queryRef={queryRef} />;
                  }
                  case "artist": {
                    const queryRef = loadQuery<ArtistPortfolioPageQueryType>(
                      environment,
                      ArtistPortfolioPageQuery,
                      {}
                    );
                    return <ArtistPortfolioPage queryRef={queryRef} />;
                  }
                }
              }}
            />
            <PrivateRoute
              path={InternalRoutes.fundDirectory}
              exact={true}
              render={() => {
                const queryRef = loadQuery<FundDirectoryQueryType>(
                  environment,
                  FundDirectoryQuery,
                  {}
                );

                return <FundDirectory queryRef={queryRef} />;
              }}
            />
            <PrivateRoute
              path={InternalRoutes.fundProfile}
              render={({
                match: {
                  params: { companySlug }
                }
              }) => {
                const queryRef = loadQuery<FundProfileQueryType>(
                  environment,
                  FundProfileQuery,
                  { slug: companySlug }
                );

                const caseStudyOfferQueryRef =
                  loadQuery<FundCaseStudyOfferTrackDataQueryType>(
                    environment,
                    FundCaseStudyOfferTrackDataQuery,
                    { companySlug }
                  );

                return (
                  <FundProfile
                    queryRef={queryRef}
                    caseStudyOfferQueryRef={caseStudyOfferQueryRef}
                  />
                );
              }}
            />
            <PrivateRoute
              path={InternalRoutes.insights}
              exact={true}
              forUserType={"artist"}
              render={() => {
                const queryRef = loadQuery<HomebaseQueryType>(
                  environment,
                  HomebaseQuery,
                  {}
                );
                return <Homebase queryRef={queryRef} />;
              }}
            />
            <SentryRoute>
              <DefaultPrivatePage />
            </SentryRoute>
          </Switch>
        </BannerProvider>
      </Suspense>
    </FluidGrid>
  );
}

function Routing(): ReactElement {
  const meQueryRef = loadQuery<useAuthMeQueryType>(environment, meQuery, {});

  return (
    <Suspense fallback={Fallback}>
      <ProvideAuth queryRef={meQueryRef}>
        <ProvideStaticData>
          <Router history={history}>
            <Routes />
          </Router>
        </ProvideStaticData>
      </ProvideAuth>
    </Suspense>
  );
}

export default Routing;
