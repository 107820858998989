import { mutationsOfferCancelOfferMutation } from "__generated__/mutationsOfferCancelOfferMutation.graphql";
import { OfferMessageCTAPartner_chat$key } from "__generated__/OfferMessageCTAPartner_chat.graphql";
import { OfferMessageCTAPartner_offer$key } from "__generated__/OfferMessageCTAPartner_offer.graphql";
import { ReactElement } from "react";
import {
  commitMutation,
  ConnectionHandler,
  graphql,
  useFragment
} from "react-relay";
import { Link, Text } from "theme-ui";

import environment from "../../../../../../environment";
import { cancelOfferMutation } from "../../../../../../mutations/mutations";

interface IOfferMessageCTAPartnerProps {
  offer: OfferMessageCTAPartner_offer$key & { status: "sent" };
  chat: OfferMessageCTAPartner_chat$key;
}

function OfferMessageCTAPartner(
  props: IOfferMessageCTAPartnerProps
): ReactElement {
  const offer = useFragment(
    graphql`
      fragment OfferMessageCTAPartner_offer on OfferNode {
        id
        status
        artist {
          displayName
        }
      }
    `,
    props.offer
  );

  const chat = useFragment(
    graphql`
      fragment OfferMessageCTAPartner_chat on ChatNode {
        id
      }
    `,
    props.chat
  );

  const messagesConnectionId = ConnectionHandler.getConnectionID(
    chat.id,
    "ChatThread_messages"
  );

  const cancelOffer = () => {
    const variables = {
      data: {
        chatNodeId: chat.id,
        offerNodeId: offer.id
      },
      connections: [messagesConnectionId]
    };
    commitMutation<mutationsOfferCancelOfferMutation>(environment, {
      mutation: cancelOfferMutation,
      variables
    });
  };

  return (
    <Text variant={"primary.bodySmall"} sx={{ textAlign: "center" }}>
      You can{" "}
      <Link
        onClick={cancelOffer}
        sx={{ textDecoration: "underline", cursor: "pointer" }}
      >
        cancel this offer
      </Link>{" "}
      at any time before <strong>{offer.artist.displayName}</strong> accepts it.
    </Text>
  );
}

export default OfferMessageCTAPartner;
