import { FundPortfolioRowDeal_offer$key } from "__generated__/FundPortfolioRowDeal_offer.graphql";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "lodash";
import numeral from "numeral";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Text, useThemeUI } from "theme-ui";

import Ic20Check from "../../../../../imgs/icons/ic20-check.svg";
import Ic24Help from "../../../../../imgs/icons/ic24-help.svg";
import Ic24UnfoldMore from "../../../../../imgs/icons/ic24-unfold-more.svg";
import AutoLayout from "../../../../components/01_Core/AutoLayout";
import { FixedGrid } from "../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../components/01_Core/Grids/GridItem";
import Divider from "../../../../components/01_Core/Miscelleneous/Divider";
import {
  Tooltip,
  TooltipIconRight
} from "../../../../components/01_Core/Tooltips/Tooltip";
import MultipleAvatars from "../../../../components/01_Core/Users/MultipleAvatars";
import { formatDollars } from "../../../../utils/money";
import { useActiveResponsiveValue } from "../../../../utils/responsiveUtils";
import { OfferDealArtistReleases } from "../03_UI_Kit/Discover/OfferDealArtistReleases";
import RowCell from "../03_UI_Kit/Discover/RowCell";
import { IconLabel } from "../03_UI_Kit/Discover/RowHeaderDeal";
import PortfolioRowDeal, { expandCssId } from "./PortfolioRowDeal";
import { RecoupmentProgressBar } from "./RecoupmentProgressBar";

dayjs.extend(relativeTime);

type FundPortfolioRowDealSize = "small" | "medium" | "large";

interface IFundPortfolioRowDealProps {
  offer: FundPortfolioRowDeal_offer$key;
  size: ResponsiveValue<FundPortfolioRowDealSize>;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=2619%3A19627
 */
function FundPortfolioRowDeal(props: IFundPortfolioRowDealProps): ReactElement {
  const offer = useFragment(
    graphql`
      fragment FundPortfolioRowDeal_offer on OfferNode {
        totalStreams
        dateOfferAccepted
        monthlyEarnings {
          edges {
            node {
              partnerEarnings
              partnerExpenses
            }
          }
        }
        copartners {
          id
          displayName
          thumbnailUrl
          company {
            displayName
          }
        }
        hasInvestmentCopartner
        ...OfferDealArtistReleases_query
        ...PortfolioRowDeal_offer
      }
    `,
    props.offer
  );

  const { theme } = useThemeUI();
  const activeSize = useActiveResponsiveValue(props.size);

  const gridTemplateAreas = (() => {
    switch (activeSize) {
      case "small":
        return `
          "ar ar ar ex"
          "st rc rc ex"
          "co cl cl ex"
        `; // 4 + 4
      case "medium":
        return `
          "ar ar ar ar co cl cl ex"
          "st st st st rc rc rc ex"
        `; // 8 + 8
      case "large":
        return `"ar ar ar ar st st rc rc co cl cl ex"`; // 12
    }
  })();

  const totalStreams = offer.totalStreams;

  const monthlyEarnings = offer.monthlyEarnings.edges.map(({ node }) => node);
  const totalMonthlyEarnings = _.sumBy(monthlyEarnings, ({ partnerEarnings }) =>
    parseFloat(partnerEarnings as string)
  );
  const totalInvested =
    0 -
    _.sumBy(monthlyEarnings, ({ partnerExpenses }) =>
      parseFloat(partnerExpenses as string)
    );

  const totalProfit = totalMonthlyEarnings - totalInvested;
  const hasRecouped = totalProfit > 0;

  const padLeft = { paddingLeft: "16px" };
  const padRight = { paddingRight: "16px" };

  return (
    <PortfolioRowDeal offer={offer}>
      <FixedGrid sx={{ gridTemplateAreas }}>
        <GridItem gridArea={"ar"}>
          <RowCell styles={["medium", "large"].includes(activeSize) && padLeft}>
            <OfferDealArtistReleases query={offer} />
          </RowCell>
        </GridItem>
        <GridItem gridArea={"co"}>
          <RowCell align={"center"}>
            <Tooltip
              content={`Other partners: ${offer.copartners
                .map(
                  partner =>
                    `${partner.displayName} (${partner.company.displayName})`
                )
                .join(", ")}`}
              size={"medium"}
            >
              <MultipleAvatars
                avatars={offer.copartners.map(partner => ({
                  id: partner.id,
                  imageUrl: partner.thumbnailUrl
                }))}
                size={activeSize === "small" ? "extraSmall" : "small"}
              />
            </Tooltip>
          </RowCell>
        </GridItem>
        <GridItem gridArea={"st"}>
          <RowCell
            align={"left"}
            styles={["medium"].includes(activeSize) && padLeft}
          >
            <Text variant={"bodyMedium"} color={"black100"}>
              {numeral(totalStreams).format("0,0")} streams
            </Text>
          </RowCell>
        </GridItem>
        <GridItem gridArea={"cl"}>
          <RowCell>
            <Text
              variant={"bodySmall"}
              color={"deepGray100"}
              title={dayjs(offer.dateOfferAccepted).format("MMMM D, YYYY")}
            >
              Closed {dayjs().to(dayjs(offer.dateOfferAccepted))}
            </Text>
          </RowCell>
        </GridItem>
        <GridItem gridArea={"ex"}>
          <RowCell
            align="right"
            styles={{
              height: "100%",
              verticalAlign: "middle",
              ...(["medium", "large"].includes(activeSize) && padRight)
            }}
          >
            <Text
              color={"midGray100"}
              id={expandCssId}
              sx={{ transition: "color 0.2s" }}
            >
              <IconLabel Icon={Ic24UnfoldMore} size="1.4em" />
            </Text>
          </RowCell>
        </GridItem>
        <GridItem gridArea={"rc"}>
          <RowCell align="center">
            {offer.hasInvestmentCopartner ? (
              <AutoLayout
                spacing={4}
                dependentProps={{ direction: "horizontal" }}
              >
                <Text variant={"bodyMedium"} color={"deepGray70"}>
                  <em>Still recouping...</em>
                </Text>
                <Tooltip
                  content={
                    "This deal is still recouping. Reach out to artist to learn more."
                  }
                  size={"medium"}
                >
                  <Ic24Help
                    color={theme.colors.deepGray70 as string}
                    viewBox={"0 0 24 24"}
                    height={"20px"}
                    width={"20px"}
                  />
                </Tooltip>
              </AutoLayout>
            ) : (
              <TooltipIconRight
                size="medium"
                content={
                  <Text>
                    Earned ${formatDollars(totalMonthlyEarnings)} with $
                    {formatDollars(totalInvested)} invested
                  </Text>
                }
                styles={{ width: "100%" }}
              >
                {hasRecouped ? (
                  <AutoLayout
                    spacing={12}
                    dependentProps={{ direction: "horizontal" }}
                  >
                    <Ic20Check color={theme.colors.upwards100 as string} />
                    <Text variant={"bodyMedium"} color={"upwards100"}>
                      +${formatDollars(totalProfit)}
                    </Text>
                  </AutoLayout>
                ) : (
                  <RecoupmentProgressBar
                    totalMonthlyEarnings={totalMonthlyEarnings}
                    totalInvested={totalInvested}
                  />
                )}
              </TooltipIconRight>
            )}
          </RowCell>
        </GridItem>
      </FixedGrid>
      <Divider />
    </PortfolioRowDeal>
  );
}

export default FundPortfolioRowDeal;
