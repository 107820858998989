import {
  CompanyInfo_company$key,
  CompanyServiceService
} from "__generated__/CompanyInfo_company.graphql";
import { CompanyInfoMutation } from "__generated__/CompanyInfoMutation.graphql";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import { ReactElement, useContext } from "react";
import { Form, FormRenderProps, useFormState } from "react-final-form";
import { graphql, useFragment, useMutation } from "react-relay";
import { PayloadError } from "relay-runtime";

import { BannerContext } from "../../../../components/01_Core/Announcements/BannerProvider";
import SelectInput from "../../../../components/01_Core/Forms/Inputs/SelectInput";
import { Field } from "../../../../components/01_Core/Forms/utils/reactFinalFormWrappers";
import {
  composeFieldValidators,
  getMinimumLengthStringValidator,
  requiredStringValidator
} from "../../../../components/01_Core/Forms/utils/validators";
import SectionProfilePhoto from "../03_UI_Kit/Forms/SectionProfilePhoto";
import AccountManagementFormContent from "./AccountManagementFormContent";
import AccountManagementHeading from "./AccountManagementHeading";
import { IEntityInfoValues } from "./AccountManagementPage";
import Footer from "./Footer";
import About from "./FormFields/About";
import CaseStudyOffer from "./FormFields/CaseStudyOffer";
import DisplayName from "./FormFields/DisplayName";
import Genre from "./FormFields/Genre";
import Location from "./FormFields/Location";
import RecipientPartner from "./FormFields/RecipientPartner";
import Slug, { slugFieldName } from "./FormFields/Slug";
import Website from "./FormFields/Website";

interface ICompanyInfoValues extends IEntityInfoValues {
  services: { value: CompanyServiceService }[];
  genres: { value: string }[];
  caseStudyOffer: { value: string };
  recipientPartner: { value: string };
  websiteUrl?: string;
}

/**
 * TODO: validation error if no services are selected
 *
 * figma: https://www.figma.com/file/IF1kneOJMIUGtuGuRnMDqn/08-Account-Management?node-id=1490%3A15101
 */
function CompanyInfo(props: {
  company: CompanyInfo_company$key;
}): ReactElement {
  const notifyBanner = useContext(BannerContext);
  const company = useFragment(
    graphql`
      fragment CompanyInfo_company on CompanyNode {
        id
        services {
          edges {
            node {
              service
            }
          }
        }
        genres {
          edges {
            node {
              id
            }
          }
        }
        caseStudyOffer {
          id
        }
        ...CaseStudyOffer_company
        recipientPartner {
          id
        }
        ...RecipientPartner_company
        displayName
        slug
        thumbnailUrl
        location
        websiteUrl
        about
      }
    `,
    props.company
  );
  const [commit] = useMutation<CompanyInfoMutation>(graphql`
    mutation CompanyInfoMutation($input: EditCompanyInfoMutationInput!) {
      editCompanyInfo(data: $input) {
        company {
          ...CompanyInfo_company
        }
      }
    }
  `);
  const onSubmit = (values: ICompanyInfoValues) => {
    return new Promise(resolve => {
      const resolveUnknownError = () =>
        resolve({ [FORM_ERROR]: "Something went wrong." });
      const { services, genres, caseStudyOffer, recipientPartner, ...rest } =
        values;

      commit({
        variables: {
          input: {
            companyNodeId: company.id,
            slug: company.slug,
            services: services?.map(({ value }) => value),
            genreNodeIds: genres.map(({ value }) => value),
            caseStudyOfferNodeId: caseStudyOffer?.value,
            recipientPartnerNodeId: recipientPartner?.value,
            ...rest
          }
        },
        onCompleted(data, errors) {
          if (
            // TODO: better error recog
            _.some(
              errors,
              (e: PayloadError) =>
                e.message.includes("Duplicate entry") &&
                e.message.includes("slug")
            )
          ) {
            return { [slugFieldName]: "That profile URL is already taken." };
          }
          if (errors != null && errors.length) {
            return resolveUnknownError();
          }

          notifyBanner({
            type: "neutral",
            children: "Your company info has been updated."
          });
          return resolve("success");
        },
        onError() {
          return resolveUnknownError();
        }
      });
    });
  };

  const {
    id,
    caseStudyOffer,
    recipientPartner,
    displayName,
    slug,
    thumbnailUrl,
    location,
    websiteUrl,
    about
  } = company;
  const initialValues = {
    services: company.services.edges.map(({ node }) => ({
      value: node.service
    })),
    genres: company.genres.edges.map(({ node }) => ({ value: node.id })),
    caseStudyOffer: { value: caseStudyOffer?.id },
    recipientPartner: { value: recipientPartner?.id },
    displayName,
    slug,
    location,
    websiteUrl,
    about
  };

  function Services() {
    const options = _.map(
      GLOBALS.PARTNER_SERVICES,
      (
        { displayName: label },
        value: keyof typeof GLOBALS.PARTNER_SERVICES
      ) => ({
        label,
        value
      })
    );

    const servicesSelected = useFormState<ICompanyInfoValues>().values.services;
    return (
      <Field<typeof options> name={"services"}>
        {({ input, meta }) => {
          const currentValue = options.filter(o =>
            input.value.map(({ value }) => value).includes(o.value)
          );
          return (
            <SelectInput
              size={["medium", "large", "large", "large"]}
              label={"Services Provided"}
              input={{ ...input, value: currentValue }}
              meta={meta}
              options={options}
              isOptionDisabled={() => servicesSelected.length >= 3}
              caption={
                "Select up to 3 services your company provides to artists."
              }
              isMulti={true}
            />
          );
        }}
      </Field>
    );
  }

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit}>
      {(formProps: FormRenderProps<ICompanyInfoValues>) => {
        return (
          <form onSubmit={formProps.handleSubmit} style={{ width: "100$" }}>
            <AccountManagementFormContent>
              <AccountManagementHeading
                key={"heading"}
                size={["small", "large", "large", "large"]}
                title={"Company info"}
                description={
                  "Let the indify community know a bit about your company."
                }
              />
              <SectionProfilePhoto
                ctaText={"Upload logo image"}
                thumbnailUrl={thumbnailUrl}
                nodeId={id}
                size={["medium", "large", "large", "large"]}
              />
              <DisplayName />
              <Slug kind={"company"} />
              <Services />
              <Genre
                fieldName="genres"
                label="Genres"
                isMulti={true}
                caption="Select up to 3 genres your company specializes in."
                isOptionDisabled={() =>
                  formProps.form.getState().values.genres.length >= 3
                }
              />
              <RecipientPartner company={company} />
              <CaseStudyOffer company={company} />
              <Location />
              <Website showErrorsBeforeTouched={true} />
              <About
                label={"How You Help Artists"}
                validator={composeFieldValidators(
                  requiredStringValidator,
                  getMinimumLengthStringValidator(
                    10,
                    "You must include at least 10 characters."
                  )
                )}
              />
              <Footer
                onClick={formProps.handleSubmit}
                loading={formProps.submitting}
                buttonDisabled={formProps.hasValidationErrors}
                size={["small", "large", "large", "large"]}
              />
            </AccountManagementFormContent>
          </form>
        );
      }}
    </Form>
  );
}

export default CompanyInfo;
