import { FeaturedDealDirectoryQuery as FeaturedDealDirectoryQueryType } from "__generated__/FeaturedDealDirectoryQuery.graphql";
import { SpecialDealsQuery as SpecialDealsQueryType } from "__generated__/SpecialDealsQuery.graphql";
import { ReactElement, Suspense, useState } from "react";
import { Form } from "react-final-form";
import { graphql, PreloadedQuery } from "react-relay";
import { Box } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import { FixedGrid } from "../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../components/01_Core/Grids/GridItem";
import SpecialDeals from "../03_UI_Kit/Deals/SpecialDeals";
import DirectoryPageTitle from "../03_UI_Kit/Discover/DirectoryPageTitle";
import RowHeaderDeal from "../03_UI_Kit/Discover/RowHeaderDeal";
import DirectorySkeleton from "./DirectorySkeleton";
import FeaturedDealDirectoryFilters, {
  IFeaturedDealsFilterForm
} from "./FeaturedDealDirectoryFilters";
import FeaturedDealDirectoryRows from "./FeaturedDealDirectoryRows";

export type FeaturedDealSortType =
  | "date"
  | "tiktokPosts"
  | "tiktokFollowers"
  | "spotify";
export type FeaturedDealSortDirection = "asc" | "desc";
export type FeaturedDealSort = {
  type: FeaturedDealSortType;
  dir: FeaturedDealSortDirection;
};

interface IFeaturedDealsDiscoverPageProps {
  queryRef: PreloadedQuery<FeaturedDealDirectoryQueryType>;
  specialDealsQueryRef: PreloadedQuery<SpecialDealsQueryType>;
}

/**
 * figma: https://www.figma.com/file/xyf6tm0ZBXnA2XM1yAl78l/03-Discover?node-id=1529%3A99627
 */
function FeaturedDealDirectory(
  props: IFeaturedDealsDiscoverPageProps
): ReactElement {
  const [sort, setSort] = useState<FeaturedDealSort>({
    type: "date",
    dir: "desc"
  });
  const [showWatchedOnly, setShowWatchedOnly] = useState<boolean>(false);
  const toggleShowWatchedOnly = () => setShowWatchedOnly(!showWatchedOnly);

  return (
    <GridItem
      gridColumn={"1 / -1"}
      sx={{ flexDirection: "column", alignItems: "center", width: "100%" }}
    >
      <Form<IFeaturedDealsFilterForm> onSubmit={() => {}}>
        {_ => (
          <FixedGrid>
            <GridItem gridColumn={"1 / -1"}>
              <DirectoryPageTitle
                size={["small", "large", "large", "large"]}
                title={"Deal Proposals"}
                description={
                  "Discover deals proposed by top independent artists.  Filter by genre, audience size, and more to find the perfect match, and make an offer to provide services or funding in exchange for a share of future earnings."
                }
              />
            </GridItem>
            <GridItem gridColumn={"1 / -1"}>
              <AutoLayout
                dependentProps={{ direction: "vertical" }}
                spacing={24}
                resizingX={FillContainer()}
              >
                <Suspense>
                  <SpecialDeals queryRef={props.specialDealsQueryRef} />
                </Suspense>
                <>
                  <FeaturedDealDirectoryFilters
                    size={["small", "medium", "large", "extraLarge"]}
                  />
                  <RowHeaderDeal
                    size={["small", "medium", "medium", "large"]}
                    sort={sort}
                    setSort={setSort}
                    showWatchedOnly={showWatchedOnly}
                    toggleShowWatchedOnly={toggleShowWatchedOnly}
                  />
                </>
              </AutoLayout>
            </GridItem>
            <GridItem gridColumn={"1 / -1"}>
              <Box
                sx={{
                  backgroundColor: "white100",
                  width: "100%",
                  border: "1px solid",
                  borderColor: "midGray70",
                  borderTop: "unset",
                  borderBottom: "unset"
                }}
              >
                <Suspense fallback={<DirectorySkeleton />}>
                  <FeaturedDealDirectoryRows
                    queryRef={props.queryRef}
                    sort={sort}
                    onlyWatching={showWatchedOnly}
                  />
                </Suspense>
              </Box>
            </GridItem>
          </FixedGrid>
        )}
      </Form>
    </GridItem>
  );
}

export const FeaturedDealDirectoryQuery = graphql`
  query FeaturedDealDirectoryQuery {
    ...FeaturedDealDirectoryRows_availableFeaturedDeals
  }
`;

export default FeaturedDealDirectory;
