/**
 * @generated SignedSource<<4b700dfc8460372807f8c381eac8f7eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomebaseGuidance_homebase$data = {
  readonly artist: {
    readonly instagramProfile: {
      readonly instagramProfileData: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly avgCommentsPerPost: number | null;
            readonly avgLikesPerPost: number | null;
          } | null;
        } | null>;
      };
    } | null;
    readonly latestInstagramFollowerCount: {
      readonly current: number;
      readonly percentChange: number | null;
    } | null;
    readonly latestTiktokFollowerCount: {
      readonly current: number;
      readonly percentChange: number | null;
    } | null;
    readonly spotifyMonthlyListeners: number | null;
    readonly spotifyMonthlyListenersWeekChange: number | null;
    readonly stats: {
      readonly streamsThisWeek: number | null;
      readonly weeklyChange: number | null;
    } | null;
    readonly tiktokProfile: {
      readonly tiktokSounds: {
        readonly " $fragmentSpreads": FragmentRefs<"RowDeal_useTikTokSoundsTotal">;
      };
    } | null;
  } | null;
  readonly " $fragmentType": "HomebaseGuidance_homebase";
};
export type HomebaseGuidance_homebase$key = {
  readonly " $data"?: HomebaseGuidance_homebase$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomebaseGuidance_homebase">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "current",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "percentChange",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomebaseGuidance_homebase",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ArtistNode",
      "kind": "LinkedField",
      "name": "artist",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ArtistStatsObject",
          "kind": "LinkedField",
          "name": "stats",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "streamsThisWeek",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "weeklyChange",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "spotifyMonthlyListeners",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "spotifyMonthlyListenersWeekChange",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StatWithDelta",
          "kind": "LinkedField",
          "name": "latestTiktokFollowerCount",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileNode",
          "kind": "LinkedField",
          "name": "instagramProfile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "last",
                  "value": 1
                }
              ],
              "concreteType": "InstagramProfileDataNodeConnection",
              "kind": "LinkedField",
              "name": "instagramProfileData",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "InstagramProfileDataNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "InstagramProfileDataNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "avgCommentsPerPost",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "avgLikesPerPost",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "instagramProfileData(last:1)"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileNode",
          "kind": "LinkedField",
          "name": "tiktokProfile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TikTokSoundNodeConnection",
              "kind": "LinkedField",
              "name": "tiktokSounds",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RowDeal_useTikTokSoundsTotal"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StatWithDelta",
          "kind": "LinkedField",
          "name": "latestInstagramFollowerCount",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "HomebaseNode",
  "abstractKey": null
};
})();

(node as any).hash = "7d72083ba75685ed491407b6c0e2a489";

export default node;
