import { Partner_authUser$key } from "__generated__/Partner_authUser.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";

import AutoLayout from "../../../../../../components/01_Core/AutoLayout";
import { useBreakpoint } from "../../../../../../utils/useBreakpoints";
import { useAuth } from "../../../../hooks/useAuth";
import { InternalRoutes } from "../../../../Routing/InternalRoutes";
import Base from "./Items/Base";

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=212%3A2218
 */
function Partner(): ReactElement {
  const breakpointMap = useBreakpoint();

  const authUser = useFragment<Partner_authUser$key>(
    graphql`
      fragment Partner_authUser on UserNode {
        partner {
          canViewPortfolio
        }
      }
    `,
    useAuth().authUser
  );

  return (
    !breakpointMap["mobile"] &&
    !breakpointMap["tablet"] && (
      <AutoLayout
        spacing={32}
        dependentProps={{ direction: "horizontal", alignment: "center" }}
        sx={{ height: "100%" }}
      >
        <Base route={InternalRoutes.featuredDealDirectory}>Deals</Base>
        <Base route={InternalRoutes.artistDirectory}>Artists</Base>
        <Base route={InternalRoutes.fundDirectory}>Partners</Base>
        {authUser.partner.canViewPortfolio && (
          <Base route={InternalRoutes.portfolio}>Portfolio</Base>
        )}
      </AutoLayout>
    )
  );
}

export default Partner;
