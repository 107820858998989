/**
 * @generated SignedSource<<d5e55a1c890c6bacfc164a62bdfb3c40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserType = "artist" | "partner";
import { FragmentRefs } from "relay-runtime";
export type useAuth_MeFragment$data = {
  readonly email: string;
  readonly id: string;
  readonly userType: UserType | null;
  readonly " $fragmentSpreads": FragmentRefs<"AuthUserAvatarName_authUser" | "ChatThread_authUser" | "FundPortfolioPage_authUser" | "Inbox_authUser" | "Partner_authUser" | "PrivateRoute_authUser" | "RowFund_userArtist" | "UserInfo_authUser" | "UserNav_authUser" | "useOfferNextStep_authUser">;
  readonly " $fragmentType": "useAuth_MeFragment";
};
export type useAuth_MeFragment$key = {
  readonly " $data"?: useAuth_MeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useAuth_MeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useAuth_MeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PrivateRoute_authUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserInfo_authUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AuthUserAvatarName_authUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChatThread_authUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOfferNextStep_authUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Inbox_authUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RowFund_userArtist"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserNav_authUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Partner_authUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FundPortfolioPage_authUser"
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};

(node as any).hash = "72649d9bac4ddd129449fc21e6dc562c";

export default node;
