/**
 * @generated SignedSource<<d3b3179a5e1fbf349b7ad79f5df5baf4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OfferStatus = "accepted" | "active" | "canceled" | "declined" | "earnings_directed" | "funds_received" | "sent" | "stripe_charge_created" | "voided";
import { FragmentRefs } from "relay-runtime";
export type OfferMessageShell_message$data = {
  readonly chat: {
    readonly other: {
      readonly name: string;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"OfferMessageCTAArtist_chat" | "OfferMessageCTAPartner_chat">;
  };
  readonly offer: {
    readonly status: OfferStatus;
    readonly " $fragmentSpreads": FragmentRefs<"OfferMessageBody_offer" | "OfferMessageCTAArtist_offer" | "OfferMessageCTAPartner_offer">;
  } | null;
  readonly " $fragmentType": "OfferMessageShell_message";
};
export type OfferMessageShell_message$key = {
  readonly " $data"?: OfferMessageShell_message$data;
  readonly " $fragmentSpreads": FragmentRefs<"OfferMessageShell_message">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OfferMessageShell_message",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OfferNode",
      "kind": "LinkedField",
      "name": "offer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OfferMessageCTAArtist_offer"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OfferMessageCTAPartner_offer"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OfferMessageBody_offer"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChatNode",
      "kind": "LinkedField",
      "name": "chat",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserNode",
          "kind": "LinkedField",
          "name": "other",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OfferMessageCTAArtist_chat"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OfferMessageCTAPartner_chat"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ChatMessageNode",
  "abstractKey": null
};

(node as any).hash = "e534ee659794536cb1c6a23eb3c530dc";

export default node;
