import { AuthUserAvatarName_authUser$key } from "__generated__/AuthUserAvatarName_authUser.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";

import { useAuth } from "../../../pages/indify/hooks/useAuth";
import { AvatarIconSize } from "./AvatarIcon";
import { ActorAvatarName } from "./AvatarName";

interface IAuthUserAvatarNameProps {
  size: ResponsiveValue<AvatarIconSize>;
}

/**
 * A relay data wrapper for <LabelledAvatar>
 */
function AuthUserAvatarName(props: IAuthUserAvatarNameProps): ReactElement {
  const authUser = useFragment<AuthUserAvatarName_authUser$key>(
    graphql`
      fragment AuthUserAvatarName_authUser on UserNode {
        artist {
          visible
          partnershipStatus
        }
        actor {
          ...AvatarName_actor
        }
      }
    `,
    useAuth().authUser
  );

  const caption = authUser.artist
    ? authUser.artist.visible
      ? GLOBALS.ARTIST_PARTNERSHIP_STATUSES[authUser.artist.partnershipStatus]
          .displayName
      : "Not Visible"
    : undefined;

  return (
    <ActorAvatarName
      actor={authUser.actor}
      size={props.size}
      caption={caption}
      disableLink
      disableActiveDot
    />
  );
}

export default AuthUserAvatarName;
