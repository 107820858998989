import { NonReferralAcceptanceArtist_referral$key } from "__generated__/NonReferralAcceptanceArtist_referral.graphql";
import { NonReferralAcceptancePartner_referral$key } from "__generated__/NonReferralAcceptancePartner_referral.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../../../components/01_Core/AutoLayout";
import { rvMap } from "../../../../../../utils/responsiveUtils";
import { BannerSize, getPadding } from "./Banner";

interface INonReferralAcceptanceProps {
  size: ResponsiveValue<BannerSize>;
  name: string;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=1432%3A24835
 */
export function NonReferralAcceptance(
  props: INonReferralAcceptanceProps
): ReactElement {
  return (
    <AutoLayout
      spacing={4}
      dependentProps={{
        direction: "vertical",
        alignment: "center"
      }}
      resizingX={FillContainer()}
      p={rvMap(props.size, getPadding)}
      bg={"white100"}
    >
      <Text variant={"bodyLarge"} color={"black100"}>
        <strong>Congrats, {props.name}!</strong>
      </Text>
      <Text variant={"bodyLarge"} color={"black100"}>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        You've been invited to get access to indify.
      </Text>
    </AutoLayout>
  );
}

interface INonReferralAcceptanceArtistProps {
  size: ResponsiveValue<BannerSize>;
  referral: NonReferralAcceptanceArtist_referral$key;
}

export function NonReferralAcceptanceArtist(
  props: INonReferralAcceptanceArtistProps
): ReactElement {
  const referral = useFragment(
    graphql`
      fragment NonReferralAcceptanceArtist_referral on IndifyToArtistReferralNode {
        recipientDisplayName
      }
    `,
    props.referral
  );

  return (
    <NonReferralAcceptance
      size={props.size}
      name={referral.recipientDisplayName}
    />
  );
}

interface INonReferralAcceptancePartnerProps {
  size: ResponsiveValue<BannerSize>;
  referral: NonReferralAcceptancePartner_referral$key;
}

export function NonReferralAcceptancePartner(
  props: INonReferralAcceptancePartnerProps
): ReactElement {
  const referral = useFragment(
    graphql`
      fragment NonReferralAcceptancePartner_referral on IndifyToPartnerReferralNode {
        recipientDisplayName
        company {
          displayName
        }
      }
    `,
    props.referral
  );

  return (
    <NonReferralAcceptance
      size={props.size}
      name={referral.recipientDisplayName}
    />
  );
}
