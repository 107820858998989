import { ReactElement } from "react";
import { Link, Text } from "theme-ui";

import AutoLayout, {
  FillContainer,
  HugContents
} from "../../../../../components/01_Core/AutoLayout";
import { InputState } from "../../../../../components/01_Core/Forms/Input";
import CheckboxInput from "../../../../../components/01_Core/Forms/Inputs/CheckboxInput";
import { Field } from "../../../../../components/01_Core/Forms/utils/reactFinalFormWrappers";
import { FieldValidator } from "../../../../../components/01_Core/Forms/utils/validators";
import { ExternalRoutes } from "../../../Routing/ExternalRoutes";
import Header from "../../03_UI_Kit/Forms/Header";
import BlockLowEmphasis from "../../03_UI_Kit/Marketing/BlockLowEmphasis";

type IAcceptTermsProps = {
  accountType: "artist" | "partner";
};

/**
 * figma: https://www.figma.com/file/OqGohDdI4vyivDgcXXH5BG/01-First-time-UX?node-id=468%3A23679
 */
function AcceptTerms(props: IAcceptTermsProps): ReactElement {
  const validateSpotify: FieldValidator<boolean | null> = value => {
    return props.accountType === "artist" && value !== true
      ? "In order to join indify you must confirm the provided Spotify profile is your own."
      : undefined;
  };
  const validateDistroWorkflow: FieldValidator<boolean | null> = value => {
    return props.accountType === "artist" && value !== true
      ? "In order to join indify you must agree to participate in our distribution workflow."
      : undefined;
  };
  const validateTOS: FieldValidator<boolean | null> = value => {
    return value !== true
      ? "In order to join indify you must confirm you accept these conditions."
      : undefined;
  };

  return (
    <AutoLayout
      dependentProps={{
        direction: "vertical",
        distribution: "packed",
        alignment: "topLeft"
      }}
      resizingY={HugContents()}
      resizingX={FillContainer()}
      spacing={48}
    >
      <Header size={["small", "small", "large", "large"]}>
        Finally, review the terms
      </Header>
      <BlockLowEmphasis size={["small", "small", "large", "large"]}>
        <Text color={"black100"} variant={"h300"}>
          Artists first, always.{" "}
        </Text>
        <Text color={"black100"} variant={"bodyMedium"}>
          To ensure that our platform upholds our core values, all partnerships
          formed on indify must use terms which adhere to three principles:
          {"\n"}
        </Text>
        <ol style={{ padding: "0 0 0 16px" }}>
          <Text color={"black100"} variant={"bodyMedium"}>
            <li>
              <strong>Artists retain ownership of masters.</strong>
            </li>
            <li>
              <strong>
                Artists receive a post-recoupment split of 50% or higher.
              </strong>
            </li>
            <li>
              <strong>Artists maintain creative control.</strong>
            </li>
          </Text>
        </ol>
        <Text color={"black100"} variant={"bodyMedium"}>
          <Link
            href={
              "https://indify.notion.site/indify-s-Three-Principles-f9433530334b4760ab8b2124d9805b63"
            }
            target={"_blank"}
            sx={{ display: "inline", textDecoration: "none" }}
          >
            <Text
              variant={"bodyMedium"}
              color={"secondary100"}
              sx={{ display: "inline", cursor: "pointer" }}
            >
              <strong>See our post</strong>
            </Text>
          </Link>{" "}
          about this for more details.
        </Text>
      </BlockLowEmphasis>
      <AutoLayout
        spacing={12}
        resizingX={HugContents()}
        resizingY={HugContents()}
        dependentProps={{
          direction: "vertical",
          distribution: "packed",
          alignment: "topLeft"
        }}
      >
        {props.accountType === "artist" ? (
          <Field name={"spotifyConfirm"} validate={validateSpotify}>
            {({ input, meta }) => {
              const hasError = (meta.error || meta.submitError) && meta.touched;
              const state: InputState = hasError ? "alert" : "normal";
              return (
                <CheckboxInput
                  input={input}
                  meta={meta}
                  size={["medium", "medium", "large", "large"]}
                  label={
                    "I confirm that any Spotify profile link that I have provided or will later provide is my own"
                  }
                  caption={
                    "indify has a zero tolerance policy for fraudulent behavior; if you are found to be falsifying information you will be blocked from future use."
                  }
                  state={state}
                />
              );
            }}
          </Field>
        ) : null}
        <Field name={"distroWorkflow"} validate={validateDistroWorkflow}>
          {({ input, meta }) => {
            const hasError = (meta.error || meta.submitError) && meta.touched;
            const state: InputState = hasError ? "alert" : "normal";
            return (
              <CheckboxInput
                input={input}
                meta={meta}
                size={["medium", "medium", "large", "large"]}
                label={[
                  {
                    kind: "normal",
                    text: "I agree to the "
                  },
                  {
                    kind: "link",
                    text: "Payout Distribution Terms",
                    href: ExternalRoutes.payoutDistributionTerms
                  },
                  {
                    kind: "normal",
                    text: " should I accept a deal on the platform"
                  }
                ]}
                caption={
                  "Participation in this workflow allows indify to ensure all parties are paid out accurately and on time."
                }
                state={state}
              />
            );
          }}
        </Field>
        <Field name={"tos"} validate={validateTOS}>
          {({ input, meta }) => {
            const hasError = (meta.error || meta.submitError) && meta.touched;
            const state: InputState = hasError ? "alert" : "normal";
            return (
              <CheckboxInput
                input={input}
                meta={meta}
                size={["medium", "medium", "large", "large"]}
                label={[
                  {
                    kind: "normal",
                    text: "I agree with the "
                  },
                  {
                    kind: "link",
                    text: "Terms of Service",
                    href: ExternalRoutes.terms
                  },
                  {
                    kind: "normal",
                    text: " and "
                  },
                  {
                    kind: "link",
                    text: "Privacy Policy",
                    href: ExternalRoutes.privacy
                  }
                ]}
                state={state}
              />
            );
          }}
        </Field>
      </AutoLayout>
    </AutoLayout>
  );
}

export default AcceptTerms;
