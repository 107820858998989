import { OfferNextStep_offer$key } from "__generated__/OfferNextStep_offer.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { Flex, Text } from "theme-ui";

import AutoLayout, {
  Fixed
} from "../../../../../../components/01_Core/AutoLayout";
import Button from "../../../../../../components/01_Core/Buttons/Button";
import { centsToDollars, formatDollars } from "../../../../../../utils/money";
import useOfferNextStep from "../../../../hooks/useOfferNextStep";
import useStripeConnectUrl from "../../../../hooks/useStripeConnectUrl";
import OfferNextStepDisplay from "./OfferNextStepDisplay";

export type OfferNextStepEnum =
  | null
  | "actionArtistReviewOffer"
  | "waitingArtistReviewOffer"
  | "actionArtistConnectStripe"
  | "actionPartnerSendTransfer"
  | "actionArtistDirectEarnings"
  | "waitingPartnerSendTransfer"
  | "waitingArtistDirectEarnings"
  | "waitingArtistConnectStripe"
  | "waitingIndifyCompleteTransfer"
  | "active"
  | "voided";

export interface IOfferStripeSourceInfo {
  accountNumber: string;
  routingNumber: string;
}

interface IOfferNextStepProps {
  offer: OfferNextStep_offer$key;
}

/**
 * TODO: Design and implement "Inactive Deal" status
 *
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=2842%3A23794
 */
function OfferNextStep(props: IOfferNextStepProps): ReactElement {
  const offer = useFragment(
    graphql`
      fragment OfferNextStep_offer on OfferNode {
        moneyTransferAmountCents
        stripeSourceInfo
        partner {
          displayName
        }
        artist {
          displayName
        }
        transferRecipient {
          user {
            id
          }
        }
        ...useOfferNextStep_offer
      }
    `,
    props.offer
  );
  const status = useOfferNextStep(offer);

  const formattedTransferAmount = offer.moneyTransferAmountCents && (
    <strong>
      ${formatDollars(centsToDollars(offer.moneyTransferAmountCents))}
    </strong>
  );

  const formattedArtistName = <strong>{offer.artist.displayName}</strong>;
  const formattedPartnerName = <strong>{offer.partner.displayName}</strong>;

  const { accountNumber, routingNumber } = (
    offer.stripeSourceInfo === null
      ? { accountNumber: "Not Found", routingNumber: "Not Found" }
      : JSON.parse(offer.stripeSourceInfo)
  ) as IOfferStripeSourceInfo;

  switch (status) {
    case "actionArtistReviewOffer":
    case "waitingArtistReviewOffer":
      return null;
    case "actionArtistDirectEarnings":
      return (
        <OfferNextStepDisplay
          badgeType={status}
          headerCaption={
            <Text>
              Earnings for all content in this deal must be directed to indify
              before{" "}
              {offer.moneyTransferAmountCents ? (
                <Text>
                  {formattedPartnerName} sends your {formattedTransferAmount}{" "}
                  money transfer
                </Text>
              ) : (
                <Text>your deal with {formattedPartnerName} takes effect</Text>
              )}
              . Our team will be in touch shortly to guide you through the
              process.
            </Text>
          }
        />
      );
    case "waitingArtistDirectEarnings":
      return (
        <OfferNextStepDisplay
          badgeType={status}
          headerCaption={
            <Text>
              Once {formattedArtistName} directs earnings to indify for all
              content in this deal,{" "}
              {offer.moneyTransferAmountCents ? (
                <Text>
                  you can send your {formattedTransferAmount} money transfer
                </Text>
              ) : (
                <Text>your deal will take effect</Text>
              )}
              .
            </Text>
          }
        />
      );
    case "actionArtistConnectStripe": {
      const stripeConnectUrl = useStripeConnectUrl(
        offer.transferRecipient.user.id
      );

      return (
        <OfferNextStepDisplay
          badgeType={status}
          headerCaption={
            <Text>
              Connect your bank account to Stripe to receive your{" "}
              {formattedTransferAmount} money transfer.
            </Text>
          }
        >
          <Button
            onClick={() => (window.location.href = stripeConnectUrl)}
            variant={"primary"}
            size={"medium"}
            disabled={false}
          >
            Connect with Stripe
          </Button>
        </OfferNextStepDisplay>
      );
    }
    case "waitingArtistConnectStripe":
      return (
        <OfferNextStepDisplay
          badgeType={status}
          headerCaption={
            <Text>
              Once {formattedArtistName} connects their bank account to Stripe,
              they will receive your {formattedTransferAmount} money transfer.
            </Text>
          }
        />
      );

    case "actionPartnerSendTransfer":
      return (
        <OfferNextStepDisplay
          badgeType={status}
          headerCaption={
            <Text>
              Transfer {formattedTransferAmount} to the below account with a
              transaction from your bank to complete this deal.
            </Text>
          }
        >
          <AutoLayout spacing={12} dependentProps={{ direction: "vertical" }}>
            <AutoLayout
              spacing={4}
              resizingX={Fixed("220px")}
              dependentProps={{
                direction: "vertical"
              }}
            >
              <Text variant={"h200"}>
                <strong>Account Number</strong>
              </Text>
              <Text variant={"bodySmall"} sx={{ fontFamily: "monospace" }}>
                {accountNumber}
              </Text>
            </AutoLayout>
            <AutoLayout
              spacing={4}
              resizingX={Fixed("220px")}
              dependentProps={{
                direction: "vertical"
              }}
            >
              <Text variant={"h200"}>
                <strong>Routing Number</strong>
              </Text>
              <Text variant={"bodySmall"} sx={{ fontFamily: "monospace" }}>
                {routingNumber}
              </Text>
            </AutoLayout>
            <AutoLayout
              spacing={4}
              resizingX={Fixed("220px")}
              dependentProps={{
                direction: "vertical"
              }}
            >
              <Text variant={"h200"}>
                <strong>Address</strong>
              </Text>
              <Flex
                sx={{
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "center"
                }}
              >
                <Text variant={"bodyMedium"}>Indify, Inc.</Text>
                <Text variant={"bodyMedium"}>611 Broadway Suite 523</Text>
                <Text variant={"bodyMedium"}>New York, NY 10012</Text>
              </Flex>
            </AutoLayout>
          </AutoLayout>
        </OfferNextStepDisplay>
      );
    case "waitingPartnerSendTransfer":
      return (
        <OfferNextStepDisplay
          badgeType={status}
          headerCaption={
            <Text>
              Once {formattedPartnerName} initiates your{" "}
              {formattedTransferAmount} money transfer, it can take up to
              several business days for it to arrive in your bank account.
            </Text>
          }
        />
      );
    case "waitingIndifyCompleteTransfer":
      return (
        <OfferNextStepDisplay
          badgeType={status}
          headerCaption={
            <Text>
              indify is processing your {formattedTransferAmount} money
              transfer.
            </Text>
          }
        />
      );
    case "active":
      return (
        <OfferNextStepDisplay
          badgeType={status}
          headerCaption={
            <Text>Congratulations! This deal is now in effect.</Text>
          }
        />
      );
    case "voided":
      return (
        <OfferNextStepDisplay
          badgeType={status}
          headerCaption={<Text>This deal is no longer in effect.</Text>}
        />
      );
  }
}

export default OfferNextStep;
