import { ReactElement } from "react";
import { generatePath, Link } from "react-router-dom";
import { Box, Flex, Text } from "theme-ui";

import usePathState from "../../hooks/usePathState";
import { InternalRoutes } from "../../Routing/InternalRoutes";
import BadgeNew from "../03_UI_Kit/Marketing/BadgeNew";
import { Subpage } from "./AccountManagementPage";

interface INavItemProps {
  label: string;
  subpage: Subpage;
  isNew?: boolean;
}

function NavItem({ label, subpage, isNew }: INavItemProps): ReactElement {
  const { value: selectedSubpage } = usePathState("settings", "subpage");
  const isActive = selectedSubpage === subpage;

  return (
    <Link to={generatePath(InternalRoutes.settings, { subpage })}>
      <Flex sx={{ alignItems: "center", height: "100%" }}>
        <Text
          variant={"bodyMedium"}
          color={isActive ? "black100" : "deepGray100"}
          sx={{
            whiteSpace: "nowrap",
            ":hover": {
              color: "black100"
            }
          }}
        >
          {isActive ? <strong>{label}</strong> : label}
        </Text>
        {isNew && (
          <Box sx={{ marginLeft: "8px" }}>
            <BadgeNew />
          </Box>
        )}
      </Flex>
    </Link>
  );
}

export default NavItem;
