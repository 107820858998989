import { MessageInput_chat$key } from "__generated__/MessageInput_chat.graphql";
import {
  KeyboardEvent,
  ReactElement,
  useEffect,
  useRef,
  useState
} from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Box, Flex } from "theme-ui";

import LoadingButton from "../../../../../components/01_Core/Buttons/LoadingButton";
import TextareaInput from "../../../../../components/01_Core/Forms/Inputs/TextareaInput";
import { Tooltip } from "../../../../../components/01_Core/Tooltips/Tooltip";
import { useArtistCanMessageCompany } from "../../../hooks/useArtistCanMessageCompany";
import { useAuth } from "../../../hooks/useAuth";

type MessengerInputSize = "small" | "medium";

export interface IMessengerInputProps {
  chat: MessageInput_chat$key;
  size: ResponsiveValue<MessengerInputSize>;
  sendMessage: (message: string) => void;
  isSending: boolean;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=624%3A124
 */
function MessageInput(props: IMessengerInputProps): ReactElement {
  const [inputValue, setInputValue] = useState("");

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const setInputRef = (r: HTMLTextAreaElement) => (inputRef.current = r);
  const focusInput = () => inputRef.current.focus();
  useEffect(focusInput, [props.chat]);

  const setTextareaHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = `${Math.min(
        144,
        inputRef.current.scrollHeight + 4
      )}px`;
    }
  };

  const handleInputChange = (v: string) => {
    setInputValue(v);
    setTextareaHeight();
  };

  useEffect(() => {
    window.addEventListener("resize", setTextareaHeight, false);
  }, []);

  useEffect(setTextareaHeight, [props.isSending]);

  const handleSubmit = () => {
    const message = inputValue.trim();
    if (message.length !== 0) {
      handleInputChange("");
      props.sendMessage(message);
    }
  };

  const chat = useFragment(
    graphql`
      fragment MessageInput_chat on ChatNode {
        ...useArtistCanMessageCompany_chat
        artistUser {
          artist {
            ...useArtistCanMessageCompany_artist
          }
        }
      }
    `,
    props.chat
  );
  const { authUser } = useAuth();
  const isArtist = authUser.userType === "artist";
  const [artistCanMessage, artistCanMessageExplanation] =
    useArtistCanMessageCompany(chat.artistUser.artist, chat, true);

  const input = (
    <Flex
      sx={{ alignItems: "center", width: "100%" }}
      onTouchEnd={focusInput}
      onClick={focusInput}
      bg={"white100"}
    >
      <Flex
        sx={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%"
        }}
      >
        <Flex sx={{ alignItems: "center", width: "100%" }}>
          <Box sx={{ width: "100%" }} py={["16px", "24px", "24px", "24px"]}>
            <TextareaInput
              size={"medium"}
              rows={1}
              sx={{ height: "unset" }}
              disabled={isArtist && !artistCanMessage}
              input={{
                value: inputValue,
                name: "message_input",
                onChange: handleInputChange,
                onSubmit: handleSubmit,
                onKeyDown: handleKeyDown
              }}
              placeholder={"Send a message..."}
              inputRef={setInputRef}
            />
          </Box>
          <LoadingButton
            variant={"primary"}
            onClick={handleSubmit}
            disabled={
              inputValue == null ||
              inputValue.length === 0 ||
              (isArtist && !artistCanMessage)
            }
            loading={props.isSending}
            size={props.size}
            ml={"16px"}
          >
            Send
          </LoadingButton>
        </Flex>
      </Flex>
    </Flex>
  );
  return isArtist && artistCanMessageExplanation ? (
    <Tooltip size={"medium"} content={artistCanMessageExplanation}>
      {input}
    </Tooltip>
  ) : !isArtist && !artistCanMessage ? (
    <Tooltip
      size={"medium"}
      content={
        "This artist used one of their 5 initial messages per month to message you. After you reply to them, they'll be able to continue the conversation."
      }
    >
      {input}
    </Tooltip>
  ) : (
    input
  );
}

export default MessageInput;
