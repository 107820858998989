import { ReactElement } from "react";

import Ic16Alert from "../../../../../../../imgs/icons/ic16-alert.svg";
import Ic16Block from "../../../../../../../imgs/icons/ic16-block.svg";
import Ic16CheckCircle from "../../../../../../../imgs/icons/ic16-check-circle.svg";
import Ic16Time from "../../../../../../../imgs/icons/ic16-time.svg";
import Ic20Suitcase from "../../../../../../../imgs/icons/ic20-suitcase.svg";
import SubtleBadge, {
  ISubtleBadgeProps
} from "../../../../../../components/01_Core/Badges_and_Tags/SubtleBadge";
import { IconBox } from "../../../../../../components/01_Core/Foundations/Icons";
import { OfferNextStepEnum } from "./OfferNextStep";

interface IOfferNextStepBadgeProps {
  type: OfferNextStepEnum;
  subtleBadgeProps?: Omit<ISubtleBadgeProps, "children" | "icon">;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=2836%3A18854
 */
function OfferNextStepBadge(props: IOfferNextStepBadgeProps): ReactElement {
  const getIcon = () => {
    switch (props.type) {
      case "actionArtistReviewOffer":
        return Ic20Suitcase;
      case "actionArtistConnectStripe":
      case "actionPartnerSendTransfer":
      case "actionArtistDirectEarnings":
        return Ic16Alert;
      case "active":
        return Ic16CheckCircle;
      case "voided":
        return Ic16Block;
      case "waitingArtistReviewOffer":
      case "waitingIndifyCompleteTransfer":
      case "waitingArtistDirectEarnings":
      case "waitingArtistConnectStripe":
      case "waitingPartnerSendTransfer":
        return Ic16Time;
    }
  };

  const getText = () => {
    switch (props.type) {
      case "actionArtistReviewOffer":
        return "New offer";
      case "waitingArtistReviewOffer":
        return "Artist reviewing offer";
      case "actionArtistConnectStripe":
        return "Stripe connection required";
      case "actionPartnerSendTransfer":
        return "Transfer required";
      case "actionArtistDirectEarnings":
        return "Earnings direction required";
      case "active":
        return "Active deal";
      case "voided":
        return "Voided deal";
      case "waitingIndifyCompleteTransfer":
        return "Waiting for indify";
      case "waitingArtistDirectEarnings":
      case "waitingArtistConnectStripe":
        return "Waiting for artist";
      case "waitingPartnerSendTransfer":
        return "Waiting for partner";
    }
  };

  return (
    <SubtleBadge
      icon={
        <IconBox
          icon={getIcon()}
          sx={{ size: props.subtleBadgeProps.size === "small" ? 16 : 20 }}
        />
      }
      {...props.subtleBadgeProps}
    >
      {getText()}
    </SubtleBadge>
  );
}

export default OfferNextStepBadge;
