import { ReactElement } from "react";
import { Flex } from "theme-ui";

import FieldBuilder from "../../../../../components/01_Core/Forms/FieldBuilder";
import TextInput from "../../../../../components/01_Core/Forms/Inputs/TextInput";
import {
  composeFieldValidators,
  FieldValidator,
  requiredStringValidator,
  urlValidator
} from "../../../../../components/01_Core/Forms/utils/validators";

export const websiteFieldName = "websiteUrl";

function Website(props: {
  label?: string;
  showErrorsBeforeTouched: boolean;
}): ReactElement {
  const validator: FieldValidator<string | null> = composeFieldValidators(
    requiredStringValidator,
    urlValidator
  );

  return (
    <Flex sx={{ flexDirection: "column", width: "100%" }}>
      <FieldBuilder
        validator={validator}
        inputField={TextInput}
        inputFieldProps={{
          size: ["medium", "large", "large", "large"],
          label: props.label || "Website",
          placeholder: "https://",
          caption:
            "If you doesn't have a website, provide a link to one of your social profiles."
        }}
        fieldName={websiteFieldName}
        showErrorsBeforeTouched={props.showErrorsBeforeTouched}
      />
    </Flex>
  );
}

export default Website;
