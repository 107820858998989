import {
  HomebaseTable_homebase$data,
  HomebaseTable_homebase$key
} from "__generated__/HomebaseTable_homebase.graphql";
import dayjs from "dayjs";
import numeral from "numeral";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { Box, Flex } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import { useBreakpoint } from "../../../../utils/useBreakpoints";

function HomebaseTable(props: {
  homebase: HomebaseTable_homebase$key;
}): ReactElement {
  const homebase = useFragment(
    graphql`
      fragment HomebaseTable_homebase on HomebaseNode {
        instagram {
          timeseries {
            x
            y
          }
        }
        tiktok {
          timeseries {
            x
            y
          }
        }
        streaming {
          timeseries {
            x
            y
          }
        }
      }
    `,
    props.homebase
  );

  const breakpointMap = useBreakpoint();

  return (
    <AutoLayout
      spacing={0}
      resizingX={FillContainer()}
      dependentProps={{ direction: "vertical" }}
    >
      <Flex
        sx={{
          width: "100%",
          "> div": {
            padding: "8px",
            flex: "1",
            border: theme => `1px solid ${theme.colors.midGray70}`,
            borderCollapse: "collapse"
          }
        }}
      >
        <Box>
          <strong>Platform</strong>
        </Box>
        <Box>
          <strong>Last Week</strong>
        </Box>
        <Box>
          <strong>Last Month</strong>
        </Box>
        <Box>
          <strong>Last 3 Months</strong>
        </Box>
        {!breakpointMap.mobile && (
          <Box>
            <strong>Last 6 Months</strong>
          </Box>
        )}
      </Flex>
      <TableRow
        label={"TikTok Followers"}
        data={{
          week: lastWeekData(homebase.tiktok.timeseries),
          month: lastMonthData(homebase.tiktok.timeseries),
          threeMonths: lastThreeMonthsData(homebase.tiktok.timeseries),
          sixMonths: lastSixMonthsData(homebase.tiktok.timeseries)
        }}
      />
      <TableRow
        label={"Instagram Followers"}
        data={{
          week: lastWeekData(homebase.instagram.timeseries),
          month: lastMonthData(homebase.instagram.timeseries),
          threeMonths: lastThreeMonthsData(homebase.instagram.timeseries),
          sixMonths: lastSixMonthsData(homebase.instagram.timeseries)
        }}
      />
      <TableRow
        label={"Streams"}
        data={{
          week: lastWeekData(homebase.streaming.timeseries),
          month: lastMonthData(homebase.streaming.timeseries),
          threeMonths: lastThreeMonthsData(homebase.streaming.timeseries),
          sixMonths: lastSixMonthsData(homebase.streaming.timeseries)
        }}
      />
    </AutoLayout>
  );
}

interface RowData {
  week: {
    change: number;
    percent: number;
  } | null;
  month: {
    change: number;
    percent: number;
  } | null;
  threeMonths: {
    change: number;
    percent: number;
  } | null;
  sixMonths: {
    change: number;
    percent: number;
  } | null;
}

interface ITableRowProps {
  label: string;
  data: RowData;
}

function TableRow(props: ITableRowProps): ReactElement {
  const breakpointMap = useBreakpoint();

  const formatCellDataString = (change: number, percent: number) => {
    const formattedChange = numeral(change).format("0[.]0a");
    const formattedPercent = numeral(percent * 100).format("+0a");

    return `${formattedChange} (${formattedPercent}%)`;
  };

  return (
    <Flex
      sx={{
        width: "100%",
        color: theme => theme.colors.deepGray100,
        "> div": {
          padding: "8px",
          flex: "1",
          border: theme => `1px solid ${theme.colors.midGray70}`,
          borderCollapse: "collapse"
        }
      }}
    >
      <Box sx={{ color: theme => theme.colors.black100 }}>{props.label}</Box>
      <Box>
        {props.data.week
          ? `${formatCellDataString(
              props.data.week.change,
              props.data.week.percent
            )}`
          : "-"}
      </Box>
      <Box>
        {props.data.month
          ? `${formatCellDataString(
              props.data.month.change,
              props.data.month.percent
            )}`
          : "-"}
      </Box>
      <Box>
        {props.data.threeMonths
          ? `${formatCellDataString(
              props.data.threeMonths.change,
              props.data.threeMonths.percent
            )}`
          : "-"}
      </Box>
      {!breakpointMap.mobile && (
        <Box>
          {props.data.sixMonths
            ? `${formatCellDataString(
                props.data.sixMonths.change,
                props.data.sixMonths.percent
              )}`
            : "-"}
        </Box>
      )}
    </Flex>
  );
}

function lastWeekData(
  data: HomebaseTable_homebase$data["tiktok"]["timeseries"]
): { change: number; percent: number } | null {
  const lastWeek = data.filter(d =>
    dayjs().subtract(1, "week").isBefore(dayjs(d.x))
  );

  if (lastWeek.length === 0) {
    return null;
  }

  const lastWeekChange =
    lastWeek.length > 1 ? lastWeek[lastWeek.length - 1].y - lastWeek[0].y : 0;
  const lastWeekPercent =
    lastWeek.length > 1 && lastWeek[0].y > 0
      ? lastWeekChange / lastWeek[0].y
      : 0;

  return { change: lastWeekChange, percent: lastWeekPercent };
}

function lastMonthData(
  data: HomebaseTable_homebase$data["tiktok"]["timeseries"]
): { change: number; percent: number } | null {
  const lastMonth = data.filter(d =>
    dayjs().subtract(1, "month").isBefore(dayjs(d.x))
  );

  if (lastMonth.length === 0) {
    return null;
  }

  const lastMonthChange =
    lastMonth.length > 1
      ? lastMonth[lastMonth.length - 1].y - lastMonth[0].y
      : 0;
  const lastMonthPercent =
    lastMonth.length > 1 && lastMonth[0].y > 0
      ? lastMonthChange / lastMonth[0].y
      : 0;

  return { change: lastMonthChange, percent: lastMonthPercent };
}

function lastThreeMonthsData(
  data: HomebaseTable_homebase$data["tiktok"]["timeseries"]
): { change: number; percent: number } | null {
  const lastThreeMonths = data.filter(d =>
    dayjs().subtract(3, "month").isBefore(dayjs(d.x))
  );

  if (lastThreeMonths.length === 0) {
    return null;
  }

  const lastThreeMonthsChange =
    lastThreeMonths.length > 1
      ? lastThreeMonths[lastThreeMonths.length - 1].y - lastThreeMonths[0].y
      : 0;
  const lastThreeMonthsPercent =
    lastThreeMonths.length > 1 && lastThreeMonths[0].y > 0
      ? lastThreeMonthsChange / lastThreeMonths[0].y
      : 0;

  return { change: lastThreeMonthsChange, percent: lastThreeMonthsPercent };
}

function lastSixMonthsData(
  data: HomebaseTable_homebase$data["tiktok"]["timeseries"]
): { change: number; percent: number } | null {
  const lastSixMonths = data.filter(d =>
    dayjs().subtract(6, "month").isBefore(dayjs(d.x))
  );

  if (lastSixMonths.length === 0) {
    return null;
  }

  const lastSixMonthsChange =
    lastSixMonths.length > 1
      ? lastSixMonths[lastSixMonths.length - 1].y - lastSixMonths[0].y
      : 0;
  const lastSixMonthsPercent =
    lastSixMonths.length > 1 && lastSixMonths[0].y > 0
      ? lastSixMonthsChange / lastSixMonths[0].y
      : 0;

  return { change: lastSixMonthsChange, percent: lastSixMonthsPercent };
}

export default HomebaseTable;
