import { ArtistDirectoryCard_artist$key } from "__generated__/ArtistDirectoryCard_artist.graphql";
import { ReactElement, ReactNode } from "react";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";
import { Box, Flex, Image, Link as ExternalLink, Text } from "theme-ui";

import Ic24Chat from "../../../../../../imgs/icons/ic24-chat.svg";
import Ic24Difference from "../../../../../../imgs/icons/ic24-difference.svg";
import Ic24OpenInNew from "../../../../../../imgs/icons/ic24-open-in-new.svg";
import Ic24VisibilityOff from "../../../../../../imgs/icons/ic24-visibility-off.svg";
import Ic24Voice from "../../../../../../imgs/icons/ic24-voice.svg";
import Ic24VolumeMax from "../../../../../../imgs/icons/ic24-volume-max.svg";
import AutoLayout from "../../../../../components/01_Core/AutoLayout";
import { DotLabel } from "../../../../../components/01_Core/Badges_and_Tags/DotLabel";
import SubtleBadge from "../../../../../components/01_Core/Badges_and_Tags/SubtleBadge";
import { IconBox } from "../../../../../components/01_Core/Foundations/Icons";
import { getUserActiveDotProps } from "../../../../../components/01_Core/Users/AvatarIcon";
import { getPlatformIcons } from "../../../../../utils/getPlatformIcons";
import { useActiveResponsiveValue } from "../../../../../utils/responsiveUtils";
import {
  formatNumeral,
  lineClampStyles,
  uploadedImageOrFallback
} from "../../../../../utils/utils";
import { InternalRoutes } from "../../../Routing/InternalRoutes";
import StatDelta from "../User_Directory/StatDelta";
import { useTikTokSoundsTotal } from "./RowDeal";
import { IconLabel, SocialIconLabel } from "./RowHeaderDeal";

interface IArtistDirectoryCardProps {
  artist: ArtistDirectoryCard_artist$key;
}

const cardBoxStyles = {
  backgroundColor: "white100",
  borderRadius: "8px",
  overflow: "hidden",
  width: "100%"
};

const statBoxStyles = {
  width: "100%",
  borderBottom: "1px solid",
  borderLeft: "1px solid",
  borderRight: "1px solid",
  borderBottomLeftRadius: "8px",
  borderBottomRightRadius: "8px",
  borderColor: "midGray70"
};

export function PlaceholderArtistDirectoryCard(): ReactElement {
  return (
    <Box
      sx={{
        ...cardBoxStyles,
        flexDirection: "column",
        display: "flex"
      }}
    >
      <Box
        sx={{
          width: "100%",
          position: "relative",
          aspectRatio: "5/4",
          backgroundColor: "midGray70"
        }}
      />
      <Box id={"stats-box"} sx={statBoxStyles}>
        <AutoLayout
          dependentProps={{ direction: "vertical" }}
          spacing={6}
          sx={{ padding: "12px", width: "100%" }}
        >
          {Array.from({ length: 5 }).map((_, i) => (
            <Box
              key={i}
              sx={{ width: "100%", backgroundColor: "lightGray100" }}
            >
              <Box sx={{ visibility: "hidden" }}>
                <SocialIconLabel platform="SPOTIFY" />
              </Box>
            </Box>
          ))}
        </AutoLayout>
      </Box>
    </Box>
  );
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=2008%3A19782
 */
export default function ArtistDirectoryCard(
  props: IArtistDirectoryCardProps
): ReactElement {
  const artist = useFragment(
    graphql`
      fragment ArtistDirectoryCard_artist on ArtistNode {
        user {
          lastActiveAt
        }
        slug
        displayName
        imageUrl
        partnershipStatus
        seekingServices {
          edges {
            node {
              service
            }
          }
        }
        genre {
          genre
        }
        stats {
          streamsThisWeek
          weeklyChange
        }
        spotifyMonthlyListeners
        spotifyMonthlyListenersWeekChange
        spotifyProfile {
          imageUrl
          url
        }
        latestTiktokFollowerCount {
          current
          percentChange
        }
        tiktokProfile {
          url
          tiktokSounds {
            ...RowDeal_useTikTokSoundsTotal
          }
        }
        latestInstagramFollowerCount {
          current
          percentChange
        }
        instagramProfile {
          url
        }
      }
    `,
    props.artist
  );

  const PartnershipStatusIcon = (() => {
    switch (artist.partnershipStatus) {
      case "ACTIVELY_LOOKING":
        return Ic24Difference;
      case "OPEN":
        return Ic24Chat;
      case "NOT_LOOKING":
        return Ic24VisibilityOff;
    }
  })();
  const seekingServiceLabels = artist.seekingServices?.edges.map(
    ({ node }) => GLOBALS.PARTNER_SERVICES[node.service].displayName
  );
  const partnershipStatusLabel =
    GLOBALS.ARTIST_PARTNERSHIP_STATUSES[artist.partnershipStatus].displayName;

  function darkenBottomOfImage(image: ReactNode) {
    return (
      <Box
        sx={{
          position: "relative",
          ":after": {
            content: "''",
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
            // full brightness on top, begins darkening by 50% after 65% of height
            background:
              "linear-gradient(rgba(0, 0, 0, 0) 65%, rgba(0, 0, 0, 0.5))"
          }
        }}
      >
        {image}
      </Box>
    );
  }

  const imageUrl = uploadedImageOrFallback(
    artist.imageUrl,
    artist.spotifyProfile?.imageUrl
  );

  function StatLine(statProps: {
    current: number;
    deltaPct: number;
    Icon: Parameters<typeof IconLabel>[0]["Icon"];
    label: string;
    url?: string;
  }) {
    const { current, deltaPct, Icon, label, url } = statProps;
    return (
      <ExternalLink
        href={url}
        target={"_blank"}
        onClick={e => e.stopPropagation()}
        sx={{
          width: "100%",
          display: "inline",
          textDecoration: "none",
          color: "black100",
          borderBottom: "1px solid",
          borderColor: "transparent",
          transition: "border-color 0.2s",
          ...(url && { ":hover": { borderColor: "deepGray100" } })
        }}
      >
        <Text
          variant={useActiveResponsiveValue([
            "bodySmall",
            "bodySmall",
            "bodyMedium",
            "bodyMedium"
          ])}
          color={"deepGray70"}
          sx={lineClampStyles(1)}
        >
          <AutoLayout
            spacing={8}
            dependentProps={{ direction: "horizontal", alignment: "center" }}
            sx={{ width: "100%" }}
          >
            <IconLabel Icon={Icon}>
              <Text
                color={current ? "deepGray100" : "midGray100"}
                sx={lineClampStyles(1)}
              >
                {current ? (
                  <Text color={"black100"}>
                    <strong>{formatNumeral(current, true)}</strong>
                  </Text>
                ) : (
                  "—"
                )}{" "}
                {label}
              </Text>
            </IconLabel>
            <AutoLayout
              spacing={12}
              dependentProps={{ direction: "horizontal", alignment: "center" }}
              sx={{ marginLeft: "auto" }}
            >
              {statProps.deltaPct != null && (
                <StatDelta
                  size={["small", "small", "large", "large"]}
                  delta={deltaPct}
                />
              )}
              <IconBox
                icon={Ic24OpenInNew}
                sx={{
                  width: "14px",
                  height: "14px",
                  color: "deepGray70",
                  visibility: url ? undefined : "hidden"
                }}
              />
            </AutoLayout>
          </AutoLayout>
        </Text>
      </ExternalLink>
    );
  }

  const tikTokSoundUGCs =
    artist.tiktokProfile &&
    useTikTokSoundsTotal(artist.tiktokProfile.tiktokSounds);

  const getPlatformIcon = (platform: Parameters<typeof getPlatformIcons>[0]) =>
    getPlatformIcons(platform)[1][0];

  const activeDotProps = getUserActiveDotProps(artist.user?.lastActiveAt);

  return (
    <Box sx={cardBoxStyles}>
      <Flex
        sx={{
          flexDirection: "column",
          ":hover": { "#stats-box": { borderColor: "deepGray100" } }
        }}
      >
        <Link
          to={generatePath(InternalRoutes.artistProfile, {
            artistSlug: artist.slug
          })}
        >
          <Box
            sx={{
              width: "100%",
              position: "relative",
              ":hover": { "#artist-name": { borderColor: "white100" } }
            }}
          >
            {darkenBottomOfImage(
              <Image
                src={imageUrl}
                sx={{
                  width: "100%",
                  aspectRatio: "5/4",
                  objectFit: "cover",
                  display: "block",
                  objectPosition: "50% 30%"
                }}
              />
            )}
            {/* Overlay artist name at bottom left */}
            <Text
              id={"artist-name"}
              variant={"h400"}
              color={"white100"}
              sx={{
                position: "absolute",
                bottom: "0.5lh",
                left: "0.5lh",
                borderBottom: "2px solid",
                borderColor: "transparent",
                transition: "border-color 0.2s",
                ...lineClampStyles(1)
              }}
            >
              <DotLabel dotProps={activeDotProps}>
                <strong>{artist.displayName}</strong>
              </DotLabel>
            </Text>
            {/* Overlay services badge at top left */}
            <Box
              sx={{
                position: "absolute",
                top: "0.5lh",
                left: "0.5lh"
              }}
            >
              <SubtleBadge
                size={"small"}
                color={"blue"}
                icon={
                  PartnershipStatusIcon && (
                    <PartnershipStatusIcon
                      style={{ width: "1.0em", height: "1.0em" }}
                    />
                  )
                }
              >
                {/* {seekingServiceLabels.length
                  ? `${seekingServiceLabels[0]}${
                      seekingServiceLabels.length > 1
                        ? ` + ${seekingServiceLabels.length - 1}`
                        : ""
                    }`
                  : partnershipStatusLabel} */}
                {partnershipStatusLabel}
              </SubtleBadge>
            </Box>
            {/* Overlay genre badge at top right */}
            {artist.genre?.genre && (
              <Box
                sx={{
                  position: "absolute",
                  top: "0.5lh",
                  right: "0.5lh"
                }}
              >
                <SubtleBadge
                  size={"small"}
                  color={"gray"}
                  icon={
                    <Ic24VolumeMax
                      style={{ width: "1.0em", height: "1.0em" }}
                    />
                  }
                >
                  {artist.genre.genre}
                </SubtleBadge>
              </Box>
            )}
          </Box>
        </Link>
        <Box
          id={"stats-box"}
          sx={{
            ...statBoxStyles,
            transition: "border-color 0.2s"
          }}
        >
          <AutoLayout
            dependentProps={{ direction: "vertical" }}
            spacing={6}
            sx={{ padding: "12px", width: "100%" }}
          >
            <StatLine
              Icon={Ic24Voice}
              label="streams last week"
              current={artist.stats?.streamsThisWeek}
              deltaPct={artist.stats && artist.stats.weeklyChange * 100}
            />
            <StatLine
              Icon={getPlatformIcon("SPOTIFY")}
              label="monthly listeners"
              current={artist.spotifyMonthlyListeners}
              deltaPct={artist.spotifyMonthlyListenersWeekChange}
              url={artist.spotifyProfile?.url}
            />
            <StatLine
              Icon={getPlatformIcon("TIKTOK")}
              label="followers"
              current={artist.latestTiktokFollowerCount?.current}
              deltaPct={artist.latestTiktokFollowerCount?.percentChange}
              url={artist.tiktokProfile?.url}
            />
            <StatLine
              Icon={getPlatformIcon("TIKTOK")}
              label="UGCs"
              current={tikTokSoundUGCs?.currentTotal}
              deltaPct={tikTokSoundUGCs?.totalPercentChange}
              url={tikTokSoundUGCs?.withHighestValue?.tiktokUrl}
            />
            <StatLine
              Icon={getPlatformIcon("INSTAGRAM")}
              label="followers"
              current={artist.latestInstagramFollowerCount?.current}
              deltaPct={artist.latestInstagramFollowerCount?.percentChange}
              url={artist.instagramProfile?.url}
            />
          </AutoLayout>
        </Box>
      </Flex>
    </Box>
  );
}
