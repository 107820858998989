/**
 * @generated SignedSource<<4737d7e11aa5bb23c7632849f8ebde60>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RowDeal_dropdown$data = {
  readonly artist: {
    readonly displayName: string;
    readonly spotifyMonthlyListeners: number | null;
    readonly spotifyMonthlyListenersWeekChange: number | null;
    readonly " $fragmentSpreads": FragmentRefs<"useMessage_actor">;
  };
  readonly editorialNote: string | null;
  readonly id: string;
  readonly tiktokSounds: {
    readonly " $fragmentSpreads": FragmentRefs<"RowDeal_useTikTokSoundsTotal">;
  };
  readonly watching: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"useFeaturedDealTitle">;
  readonly " $fragmentType": "RowDeal_dropdown";
};
export type RowDeal_dropdown$key = {
  readonly " $data"?: RowDeal_dropdown$data;
  readonly " $fragmentSpreads": FragmentRefs<"RowDeal_dropdown">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RowDeal_dropdown",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "watching",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "editorialNote",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TikTokSoundNodeConnection",
      "kind": "LinkedField",
      "name": "tiktokSounds",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RowDeal_useTikTokSoundsTotal"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ArtistNode",
      "kind": "LinkedField",
      "name": "artist",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useMessage_actor"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "spotifyMonthlyListeners",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "spotifyMonthlyListenersWeekChange",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useFeaturedDealTitle"
    }
  ],
  "type": "FeaturedDealNode",
  "abstractKey": null
};

(node as any).hash = "a0a24f927469610463fbd2f5902798af";

export default node;
