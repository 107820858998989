import {
  Terms_featuredDeal$data,
  Terms_featuredDeal$key
} from "__generated__/Terms_featuredDeal.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { ResponsiveValue } from "styled-system";
import { Link as ExternalLink, Text } from "theme-ui";

import Ic20Email from "../../../../../../imgs/icons/ic20-email.svg";
import Ic20OpenInNew from "../../../../../../imgs/icons/ic20-open-in-new.svg";
import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import Button from "../../../../../components/01_Core/Buttons/Button";
import LoadingButton from "../../../../../components/01_Core/Buttons/LoadingButton";
import Divider from "../../../../../components/01_Core/Miscelleneous/Divider";
import ReleasesInfoRemovable from "../../../../../components/01_Core/Releases/ReleasesInfoRemovable";
import { ActorAvatarIcon } from "../../../../../components/01_Core/Users/AvatarIcon";
import { useActiveResponsiveValue } from "../../../../../utils/responsiveUtils";
import { useAuth } from "../../../hooks/useAuth";
import { useMessageActor } from "../../../hooks/useMessage";
import ConditionalLink from "../../../Routing/ConditionalLink";
import { InternalRoutes } from "../../../Routing/InternalRoutes";
import { IconLabel } from "../Discover/RowHeaderDeal";

type TermsSize = "small" | "large";

interface ITermsProps {
  size: ResponsiveValue<TermsSize>;
  featuredDeal: Terms_featuredDeal$key;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=2364%3A17779
 */
function Terms(props: ITermsProps): ReactElement {
  const featuredDeal = useFragment(
    graphql`
      fragment Terms_featuredDeal on FeaturedDealNode {
        id
        releases {
          edges {
            node {
              id
              soundLink
              ...ReleasesInfoRemovable_release
            }
          }
        }
        unreleasedReleases {
          edges {
            node {
              id
              soundLink
              ...ReleasesInfoRemovable_release
            }
          }
        }
        termLengthMonths
        partnerSplitPreRecoupment
        partnerSplitPostRecoupment
        artist {
          ...useMessage_actor
          ...AvatarIcon_actor
          slug
          displayName
          managerName
          managerCompany
          managerLink
        }
        ...ReleasesInfoRemovable_offerFeaturedDeal
      }
    `,
    props.featuredDeal
  );

  const authUser = useAuth().authUser;
  const activeSize = useActiveResponsiveValue(props.size);
  const [messageArtist, isInFlight] = useMessageActor(featuredDeal.artist);

  const ctaDisabled = !authUser || authUser.userType === "artist";

  const allReleases: (
    | Terms_featuredDeal$data["releases"]["edges"][number]["node"]
    | Terms_featuredDeal$data["unreleasedReleases"]["edges"][number]["node"]
  )[] = [].concat(
    featuredDeal.releases.edges.map(e => e.node),
    featuredDeal.unreleasedReleases.edges.map(e => e.node)
  );

  return (
    <AutoLayout
      spacing={16}
      dependentProps={{ direction: "vertical" }}
      resizingX={FillContainer()}
      sx={
        activeSize === "large" && {
          padding: "16px",
          border: "1px solid",
          borderColor: "midGray70",
          borderRadius: "6px"
        }
      }
    >
      <Text variant={"bodyLarge"}>Proposed Partnership</Text>
      {activeSize === "large" && <Divider />}
      {/* Deal Terms - Releases Included */}
      <Term label={"Releases Included"} suppressDivider={true}>
        {allReleases.map(r => (
          <ReleasesInfoRemovable
            key={r.id}
            size={activeSize}
            release={r}
            offerFeaturedDeal={featuredDeal}
          />
        ))}
      </Term>
      {/* Deal Terms - Term Length */}
      <Term label={"Term Length"}>
        <Text variant={"bodyMedium"}>
          {featuredDeal.termLengthMonths ? (
            <>
              <strong>{featuredDeal.termLengthMonths} months </strong>(
              {Math.floor(featuredDeal.termLengthMonths / 12)} years)
            </>
          ) : (
            "Negotiable"
          )}
        </Text>
      </Term>
      {/* Deal Terms - Splits */}
      <Term label={"Partner Splits"}>
        <AutoLayout spacing={24} dependentProps={{ direction: "horizontal" }}>
          <AutoLayout spacing={0} dependentProps={{ direction: "vertical" }}>
            <Text variant={"bodyMedium"}>
              <strong>
                {featuredDeal.partnerSplitPreRecoupment ?? "Negotiable "}%
              </strong>
            </Text>
            <Text variant={"bodyMedium"}>Pre-recoupment</Text>
          </AutoLayout>
          <AutoLayout spacing={0} dependentProps={{ direction: "vertical" }}>
            <Text variant={"bodyMedium"}>
              <strong>
                {featuredDeal.partnerSplitPostRecoupment ?? "Negotiable "}%
              </strong>
            </Text>
            <Text variant={"bodyMedium"}>Post-recoupment</Text>
          </AutoLayout>
        </AutoLayout>
      </Term>
      {/* Deal Terms - Management */}
      {(featuredDeal.artist.managerName ||
        featuredDeal.artist.managerLink ||
        featuredDeal.artist.managerCompany) && (
        <Term label={"Management"}>
          <AutoLayout spacing={4} dependentProps={{ direction: "vertical" }}>
            {(featuredDeal.artist.managerName ||
              featuredDeal.artist.managerLink) &&
              (featuredDeal.artist.managerLink ? (
                <ExternalLink
                  href={featuredDeal.artist.managerLink}
                  target={"_blank"}
                  sx={{ textDecoration: "none" }}
                >
                  <IconLabel Icon={Ic20OpenInNew} iconSide="right" size="0.9em">
                    {featuredDeal.artist.managerName ?? "Website"}
                  </IconLabel>
                </ExternalLink>
              ) : (
                featuredDeal.artist.managerName
              ))}
            {featuredDeal.artist.managerCompany && (
              <Text variant={"bodyMedium"}>
                {featuredDeal.artist.managerCompany}
              </Text>
            )}
          </AutoLayout>
        </Term>
      )}
      {/* Deal Terms - Message Artist */}
      <Term label={"Have a question?"}>
        <AutoLayout
          spacing={11}
          resizingX={FillContainer()}
          dependentProps={{ direction: "vertical" }}
        >
          <LoadingButton
            onClick={messageArtist}
            variant={"secondary"}
            size={"medium"}
            disabled={ctaDisabled}
            loading={isInFlight}
            sx={{ width: "100%" }}
            iconProps={{
              placement: "left",
              icon: <Ic20Email />
            }}
          >
            Message {featuredDeal.artist.displayName}
          </LoadingButton>
          <ConditionalLink
            to={generatePath(InternalRoutes.artistProfile, {
              artistSlug: featuredDeal.artist.slug
            })}
            disabled={ctaDisabled}
            style={{ width: "100%" }}
          >
            <Button
              variant={"tertiary"}
              size={"medium"}
              disabled={ctaDisabled}
              sx={{ width: "100%" }}
            >
              <AutoLayout
                spacing={8}
                dependentProps={{ direction: "horizontal" }}
              >
                <ActorAvatarIcon
                  size={"extraSmall"}
                  actor={featuredDeal.artist}
                />
                <Text>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  View {featuredDeal.artist.displayName}'s profile
                </Text>
              </AutoLayout>
            </Button>
          </ConditionalLink>
          <Text variant={"bodySmall"} color={"deepGray100"}>
            {"Typical response times on indify are < 24 hours"}
          </Text>
        </AutoLayout>
      </Term>
    </AutoLayout>
  );
}

function Term(props: {
  label: string;
  suppressDivider?: boolean;
  children: ReactElement | ReactElement[];
}): ReactElement {
  const { children, label, suppressDivider, ...rest } = props;
  return (
    <AutoLayout
      spacing={8}
      resizingX={FillContainer()}
      dependentProps={{ direction: "vertical" }}
      {...rest}
    >
      {[
        !suppressDivider && <Divider key={`termDivider_${label}`} />,
        <Text key={`termLabel_${label}`} variant={"h200"} color={"deepGray100"}>
          {label}
        </Text>,
        children
      ]}
    </AutoLayout>
  );
}

export default Terms;
