import { Inbox_authUser$key } from "__generated__/Inbox_authUser.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { ResponsiveValue } from "styled-system";
import { Box, Flex, Text, useThemeUI } from "theme-ui";

import Ic24Chat from "../../../../../../../../imgs/icons/ic24-chat.svg";
import { rvMap } from "../../../../../../../utils/responsiveUtils";
import { useAuth } from "../../../../../hooks/useAuth";
import { InternalRoutes } from "../../../../../Routing/InternalRoutes";

export type InboxSize = "small" | "large";

interface IInboxProps {
  size: ResponsiveValue<InboxSize>;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=212%3A2081
 */
function Inbox(props: IInboxProps): ReactElement {
  const { theme } = useThemeUI();
  const numUnreadMessages = useFragment<Inbox_authUser$key>(
    graphql`
      fragment Inbox_authUser on UserNode {
        numUnreadMessages
      }
    `,
    useAuth().authUser
  ).numUnreadMessages;

  return (
    <Link to={generatePath(InternalRoutes.inbox)}>
      <Flex
        paddingLeft={["12px", "12px", "16px", "16px"]}
        paddingRight={"12px"}
        paddingY={"8px"}
        sx={{
          alignItems: "center",
          cursor: "pointer",
          border: "1px solid",
          borderColor: "midGray70",
          borderRadius: "4px",
          height: rvMap(props.size, getHeight),
          ":hover": {
            borderColor: "deepGray100"
          },
          transition: "border-color 0.2s"
        }}
      >
        <Text
          variant={"bodyMedium"}
          color={"black100"}
          mr={10}
          sx={{
            display: ["none", "none", "initial", "initial"],
            cursor: "pointer"
          }}
        >
          Inbox
        </Text>
        {numUnreadMessages > 0 ? (
          <Counter unreadCount={numUnreadMessages} />
        ) : (
          <Ic24Chat
            color={theme.colors.black100 as string}
            viewBox={"0 0 24 24"}
            height={"20px"}
            width={"20px"}
          />
        )}
      </Flex>
    </Link>
  );
}

function Counter(props: { unreadCount: number }) {
  return (
    <Box
      p={"5px 8px 3px 8px"}
      sx={{ borderRadius: "3px", backgroundColor: "black100" }}
    >
      <Text variant={"bodySmall"} color={"white100"}>
        <strong>{props.unreadCount}</strong>
      </Text>
    </Box>
  );
}

function getHeight(size: InboxSize) {
  switch (size) {
    case "small":
      return "40px";
    case "large":
      return "44px";
  }
}

export default Inbox;
