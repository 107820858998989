/**
 * @generated SignedSource<<5f14defec76fdde416d1c7097114650f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomebaseTable_homebase$data = {
  readonly instagram: {
    readonly timeseries: ReadonlyArray<{
      readonly x: any | null;
      readonly y: number | null;
    } | null> | null;
  } | null;
  readonly streaming: {
    readonly timeseries: ReadonlyArray<{
      readonly x: any | null;
      readonly y: number | null;
    } | null> | null;
  } | null;
  readonly tiktok: {
    readonly timeseries: ReadonlyArray<{
      readonly x: any | null;
      readonly y: number | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "HomebaseTable_homebase";
};
export type HomebaseTable_homebase$key = {
  readonly " $data"?: HomebaseTable_homebase$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomebaseTable_homebase">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "HomebaseTimeseriesNode",
    "kind": "LinkedField",
    "name": "timeseries",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "x",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "y",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomebaseTable_homebase",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "HomebaseInstagramDataNode",
      "kind": "LinkedField",
      "name": "instagram",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HomebaseTikTokDataNode",
      "kind": "LinkedField",
      "name": "tiktok",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HomebaseStreamingDataNode",
      "kind": "LinkedField",
      "name": "streaming",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "HomebaseNode",
  "abstractKey": null
};
})();

(node as any).hash = "a8b8505882d9fac17890dd7ff71e4fba";

export default node;
