import { ReactElement, SyntheticEvent } from "react";

import IconButton from "../../../../../../../components/01_Core/Buttons/IconButton";
import { getBookmarkElement } from "../../../Discover/BookmarkIcon";

export type WatchActionSize = "small" | "large";
interface IWatchActionProps {
  size: WatchActionSize;
  toggleWatch: () => void;
  isWatching: boolean;
}

/**
 * figma:
 *   https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=226%3A1676
 */
function WatchAction(props: IWatchActionProps): ReactElement {
  function onClick(e: SyntheticEvent) {
    e.stopPropagation();
    props.toggleWatch();
  }
  const IconElement = getBookmarkElement(props.isWatching);
  const buttonProps = {
    onClick,
    variant: "tertiary",
    disabled: false
  };

  return (
    <IconButton
      {...buttonProps}
      size={getSize(props.size)}
      sxOverrides={{ flexShrink: 0 }}
    >
      {props.size === "small" ? (
        <IconElement
          color={"black100"}
          viewBox={"0 0 20 20"}
          height={"16px"}
          width={"16px"}
        />
      ) : (
        <IconElement color={"black100"} />
      )}
    </IconButton>
  );
}

function getSize(size: WatchActionSize) {
  switch (size) {
    case "small":
      return "small";
    case "large":
      return "medium";
  }
}

export default WatchAction;
